<!-- 这个新测试是从AudioTestItem复制过来的，暂时保留了一些AudioTest的处理逻辑 -->
<template>
  <div id="outsideContainer">
    <div class="card-wrapper">
      <div class="card-content">
        <div class="card-items">
          <div class="test-progress" v-if="testItem.studentAnswers">
            {{ currentTestingVocIndex + 1 }}/{{
              testItem.studentAnswers.length
            }}
          </div>
          <div v-if="startTestButtonVisible">
            <el-button type="warning" round @click="handleStartTextButtonClick"
              >开始测试</el-button
            >
          </div>
          <div v-if="testItem.type <= 3" class="test-audio-icon">
            <el-tooltip
              class="box-item"
              effect="light"
              content="点击播放，请注意剩余次数"
              placement="top"
            >
              <span
                class="iconfont icon-voc-audio"
                @click="handleAudioBtnClick"
              ></span>
            </el-tooltip>
          </div>

          <div
            v-if="
              testItem.studentAnswers &&
              testItem.studentAnswers[currentTestingVocIndex]
                .multiMeaningIndex &&
              playSampleSentence
            "
          >
            <el-button type="warning" round @click="handlePlaySampleSentence"
              >播放例句</el-button
            >
          </div>

          <div class="chinese-answer-item">
            <span
              v-if="testItem.type == 6"
              @click="handleAudioBtnClick"
              v-html="
                mapCnTextMeaning(
                  testItem.studentAnswers[currentTestingVocIndex].chineseText
                )
              "
            />
          </div>

          <div
            class="chinese-answer-item"
            @contextmenu.prevent="onRightClickEnglishText"
            translate="no"
          >
            <span
              v-if="testItem.type == 4"
              @click="handleEnglishTextClick"
              v-html="
                testItem.studentAnswers[currentTestingVocIndex].englishText
              "
            />
          </div>

          <div
            class="test-listening-count"
            v-if="
              testItem.studentAnswers &&
              testItem.type != 4 &&
              testItem.type != 6
            "
          >
            剩余播放次数：{{
              testItem.studentAnswers[currentTestingVocIndex].listeningCount
            }}
          </div>

          <div class="test-listening-count">按回车可直接进入下一个单词</div>

          <div class="count-down-text">
            <voc-count-down
              ref="vocCountDown"
              @on-count-down-finished="handleCountDownFinish"
            />
          </div>
          <div class="test-item" v-if="testItem.type != 1 && test.isTypingCn">
            <el-input
              ref="CnInputBox"
              v-model="this.test.currentCnType"
              placeholder="中文意思"
              @blur="refocusCnInput"
              @keyup.enter="onInputCnEnter"
              :style="{ width: '30%' }"
            ></el-input>
          </div>

          <div class="test-item" v-if="test.isTypingEn">
            <el-input
              ref="EnInputBox"
              v-model="this.test.currentEnType"
              @blur="test.enTypeDisabled = true"
              @focus="handleEnTypeFocus"
              :disable="test.enTypeDisabled"
              placeholder="英文拼写"
              :style="{ width: '30%' }"
            ></el-input>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="next-btn">
      <el-button type="primary" round @click="handleNextClick">NEXT</el-button>
    </div> -->

    <div>
      <audio ref="vocaudio"></audio>
    </div>

    <el-dialog v-model="instructionDialogVisible" width="60%">
      <template #header>
        <span style="color: black">测试须知</span>
      </template>
      <div>
        <div class="custom-alert-title">
          <span style="color: #b88230; font-size: 1.2rem">测试前注意</span>
        </div>
        <div class="custom-alert">
          <li
            v-html="
              '每个单词限时完成，中文释义默认12s，英文拼写默认12s，输入完成可按“Enter”键提前提交答案。'
            "
          ></li>
        </div>
        <div class="custom-alert">
          <li
            v-html="
              '在看英写中模式，请勿点击英文单词，点击将直接进入下一个单词。'
            "
          ></li>
        </div>
        <div class="custom-alert-warning">
          <span v-html="customAlert.message6"></span>
        </div>
        <div class="custom-alert">
          <li
            v-html="
              '中文回答，读音一样的答案也会判定为正确，所以如果发现自己有些词打不出，可以用同音词代替。'
            "
          ></li>
        </div>
        <div class="custom-alert">
          <li v-html="customAlert.message1"></li>
        </div>

        <div class="custom-alert-warning">
          <span v-html="customAlert.message5"></span>
        </div>

        <!-- <el-alert
          show-icon
          title="注意：英文拼写如需修改只能从末尾删除重新键入，无法移动光标在词中修改。"
          type="warning"
        /> -->
        <div class="custom-alert" style="margin-top: 15px">
          <li v-html="customAlert.message2"></li>
        </div>
        <div class="custom-alert-title">
          <span style="color: #b88230; font-size: 1.2rem">测试中</span>
        </div>
        <div class="custom-alert" style="margin-top: 15px">
          <li v-html="customAlert.message3"></li>
        </div>
        <div class="custom-alert" style="margin-top: 15px">
          <span v-html="customAlert.message4"></span>
        </div>
        <div class="custom-alert" style="margin-top: 15px">
          <li
            v-html="
              '如果有紧急情况需要暂时退出，请把当前单词回答完整后再退出。'
            "
          ></li>
        </div>
        <div class="custom-alert-title">
          <span style="color: #b88230; font-size: 1.2rem">测试后</span>
        </div>
        <div class="custom-alert" style="margin-top: 15px">
          <li
            v-html="'测试中错误的词会自动在各位同学的Voc账号中标记心号。'"
          ></li>
        </div>
        <div class="custom-alert" style="margin-top: 15px">
          <li
            v-html="
              '每个同学的中文表述各有不同，有些答案系统可能无法识别，如有疑问，可以联系自己的助教老师。'
            "
          ></li>
        </div>
      </div>

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="instructionDialogVisible = false"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { ElNotification } from 'element-plus'
import VocCountDown from '../recordtotest/VocCountDown.vue'
import pinyin from 'pinyin'
export default {
  name: 'NewTestItem',
  components: { VocCountDown },

  computed: {
    mapCnTextMeaning () {
      return text => {
        let rawCnMeaningArray = []
        if (text.includes('释义：')) {
          rawCnMeaningArray = this.retrieveCnMeaning(text)
        } else {
          rawCnMeaningArray.push(text)
        }

        //let cnTextArray = rawCnMeaning.split(/[,，.。、;；:：?？!！ ]/);
        let resultCnArray = []
        for (let j = 0; j < rawCnMeaningArray.length; j++) {
          let cnTextArray =
            rawCnMeaningArray[j].split(/[,，.。、;；:：?？!！ ]/)
          for (let i = 0; i < cnTextArray.length; i++) {
            if (this.isChinese(cnTextArray[i])) {
              resultCnArray.push(cnTextArray[i])
            }
          }
        }

        return resultCnArray.join('，')
      }
    }
  },

  data () {
    return {
      audioText: '',
      printingTextInterval: null,
      decodedQueryObject: null,
      testItem: {},
      currentTestingVocIndex: 0,
      test: {
        isRecording: false,
        testInitialized: false,
        mode: 0, //1,2,3种模式，对应录音，录音+拼写，拼写
        isTypingEn: false,
        isTypingCn: false,
        currentEnType: '',
        currentCnType: '',
        enActive: false, //临时储存中文输入法的时候，打出来的单词
        enTypeDisabled: true
      },
      startTestButtonVisible: true,
      playSampleSentence: true,
      initialCountDownSetting: 5,
      initialEnTypeCountDownSetting: 3,
      currentOverviewId: null,
      instructionDialogVisible: false,
      customAlert: {
        message1:
          "若测试同时考察中英文，每个词会以<span style='color:red;'>先中文后拼写</span>的形式测试。系统会<span style='color:red;'>自动切换</span>中英输入法。",
        message2:
          "听力词汇的测试音频默认最多播放2遍。第一次音频自动播放，点击测试界面中的“黄色小喇叭”按钮 <span class='iconfont icon-voc-audio' style='color: orange'></span> 可以再次播放音频。",
        message3:
          "多义词的考察会以同个单词多次出现的方式实现，每次出现<span style='color:red'>仅需</span>写出该词汇的一个意思。",
        message4:
          "&nbsp;&nbsp;E.g. 测试中有decline这个词汇，decline会在测试中<span style='color:red'>连续出现</span>两次，一次写“下降”，另一次写“拒绝”，任意顺序皆可。如果不能将两个意思<span style='color:red'>都写</span>正确，该词汇将会被标心。",
        message5:
          "<span class='mark-heart iconfont heart icon-marked-outdated' style='color: red; font-size: 1.2rem; margin-right: 1rem'></span>注意：英文拼写如需修改只能从末尾删除重新键入，无法移动光标在词中修改。",
        message6:
          "<span class='mark-heart iconfont heart icon-marked-outdated' style='color: red; font-size: 1.2rem; margin-right: 1rem'></span>注意：在任意测试模式下，当测试者离开当前页面时，将自动提交当前答案；所以测试开始后，请勿离开当前页面。"
      }
    }
  },

  watch: {
    'test.isTypingEn': {
      handler (newValue) {
        if (newValue) {
          this.$nextTick(() => {})
        }
      },
      deep: true
    },
    'test.isTypingCn': {
      handler (newValue) {
        if (newValue) {
          this.$nextTick(() => {
            this.$refs.CnInputBox.focus()
          })
        }
      },
      deep: true
    }
  },

  beforeMount: function () {
    // decode query string into object
    let queryString = window.atob(this.$route.query.encodedString)
    queryString = window.decodeURIComponent(queryString)
    this.decodedQueryObject = JSON.parse(queryString)
  },
  mounted: function () {
    document.addEventListener('keydown', this.handleNewTestKeyDown)
    document.addEventListener('keyup', this.handleAudioTestKeyUp)
    document.addEventListener('mouseup', this.handleDocumentMouseUp)
    //判断用户是否持续看见当前测试页面的事件
    document.addEventListener('visibilitychange', this.handleVisibilityChange)
    //判断用户鼠标是否已经移动到当前页面之外
    document.addEventListener('mousemove', this.handleMouseMove)
    //set background color
    document
      .querySelector('body')
      .setAttribute('style', 'background-color: rgb(246, 247, 251)')

    //初始化用户角色
    this.userRole = localStorage.getItem('userRole')
    this.getTestById(
      this.decodedQueryObject.id,
      this.decodedQueryObject.studentId
    )

    //加载完成后，直接显示“测试须知”
    this.instructionDialogVisible = true

    //加入beforeunload事件，以便在程序退出的时候，执行一次操作
    //window.addEventListener("beforeunload", this.handleBeforeUnload);
  },

  beforeUnmount: function () {
    document.removeEventListener('keyup', this.handleAudioTestKeyUp) // remove global key down event before leaving voc page
    document.removeEventListener('keydown', this.handleNewTestKeyDown)
    document.removeEventListener('mouseup', this.handleDocumentMouseUp)
    //判断用户是否持续看见当前测试页面的事件
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibilityChange
    )
    document.removeEventListener('mousemove', this.handleMouseMove)
    //window.removeEventListener("beforeunload", this.handleBeforeUnload);
  },

  methods: {
    handleStartTextButtonClick: function () {
      this.startTestButtonVisible = false
      this.handleAudioBtnClick()
    },
    getTestById: function (testId, studentId) {
      this.$store
        .dispatch('StudentTest/getTestById', {
          id: testId,
          userId: studentId
        })
        .then(response => {
          //测试页面可能是从task任务进的，这时候decodedQueryObject不完整，需要补齐
          //如果query里面有task就说明是task过来的，所以初始化
          if (this.decodedQueryObject.task) {
            this.initializeDecodedQueryObject(response.data)
          }

          //状态是finished或者最后一个音频有东西，就跳转到结果页面
          if (
            response.data.status == 'finished' ||
            (response.data.studentAnswers[
              response.data.studentAnswers.length - 1
            ].chineseStudentAnswer &&
              response.data.studentAnswers[
                response.data.studentAnswers.length - 1
              ].chineseStudentAnswer.length > 0)
          ) {
            //如果是从result退回来的，看一下status，是finished就继续进入result页面
            this.OpenInCurrentPageWithCryption(
              '/newtestitemresult',
              this.decodedQueryObject
            )
          } else {
            this.testItem = response.data
            //1和6只要写英文，所以不用处理多义词
            if (this.testItem.type != 1 || this.testItem.type != 6)
              this.processMultimeaningVoc()
            //初始化开始下标（有可能上一次已经测过一部分）
            this.initializeStartIndex()
            this.initializeTestCountDown()
          }
        })
        .catch(error => {
          this.$message(error.toString())
        })
    },

    initializeDecodedQueryObject: function (testData) {
      let task = this.decodedQueryObject.task
      //testItem也就是testData先整体赋值给decodedQueryObject，然后调整
      this.decodedQueryObject = testData
      this.decodedQueryObject.task = task || null
    },

    processMultimeaningVoc: function () {
      //1和6是拼写测试，听或者看，写拼写，所以不用处理多义词
      if (this.testItem.type == 1 || this.testItem.type == 6) return
      for (let i = 0; i < this.testItem.studentAnswers.length; i++) {
        let extraMeaningCount = this.extraMultimeaningCount(
          this.testItem.studentAnswers[i]
        )
        if (extraMeaningCount > 0) {
          // 添加一个多义词的index标记
          this.testItem.studentAnswers[i].multiMeaningIndex = 1
          //进入多义词的时候，缓存答案
          if (this.testItem.studentAnswers[i].chineseStudentAnswer) {
            this.testItem.studentAnswers[i].cachedChineseStudentAnswer =
              this.testItem.studentAnswers[i].chineseStudentAnswer
          }

          let multiStudentAnswersArray = []
          if (
            this.testItem.studentAnswers[i].chineseStudentAnswer &&
            this.testItem.studentAnswers[i].chineseStudentAnswer.length > 0 &&
            this.testItem.studentAnswers[i].chineseStudentAnswer.includes('&')
          ) {
            multiStudentAnswersArray =
              this.testItem.studentAnswers[i].chineseStudentAnswer.split('&')
            this.testItem.studentAnswers[i].chineseStudentAnswer =
              multiStudentAnswersArray[0]
          }
          for (let j = 0; j < extraMeaningCount; j++) {
            this.testItem.studentAnswers.splice(
              i + j,
              0,
              JSON.parse(JSON.stringify(this.testItem.studentAnswers[i + j]))
            )
            this.testItem.studentAnswers[i + 1 + j].multiMeaningIndex += 1
            this.testItem.studentAnswers[i + 1 + j].chineseStudentAnswer =
              multiStudentAnswersArray[1 + j]
          }
        }
        //新增了几个单词，所以遍历的index要跳过这几个新加的单词
        i += extraMeaningCount
      }
    },

    extraMultimeaningCount: function (voc) {
      let multiMarker = ['②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨']
      // let lastMultiMeaningNumber = "";
      let extraMeaningCount = 0
      for (let i = 0; i < multiMarker.length; i++) {
        if (voc.chineseText.includes(multiMarker[i])) {
          // lastMultiMeaningNumber = multiMarker[i];
          extraMeaningCount = i + 1
        }
      }
      return extraMeaningCount
    },

    initializeTestCountDown: function () {
      this.initialCountDownSetting = parseInt(this.testItem.countDown)
      this.initialEnTypeCountDownSetting = parseInt(
        this.decodedQueryObject.enAnswerCountDown
      )
    },

    //3,4只写中，1，6只写英； 所以判断的时候，不需要中英文一起; 2是中英文
    initializeStartIndex: function () {
      //要反着来，从最后一个null开始，直到碰见第一个不是null的值，就是开始的单词
      //这里-2 因为要从倒数第二个词算起，否则如果最后一个词是有答案的，那 i + 1就会超过数组范围
      for (let i = this.testItem.studentAnswers.length - 2; i >= 0; i--) {
        if (this.testItem.type == 2) {
          if (
            this.testItem.studentAnswers[i].englishStudentAnswer &&
            this.testItem.studentAnswers[i].englishStudentAnswer.length > 0 &&
            this.testItem.studentAnswers[i].chineseStudentAnswer &&
            this.testItem.studentAnswers[i].chineseStudentAnswer.length > 0
          ) {
            //有值说明已经回答过，所以后一个 i + 1是下次应该开始的位置
            this.currentTestingVocIndex = i + 1
            this.moveToNextItemWhenOpen()
            console.log('after type 2')
            return
          }
        } else if (this.testItem.type == 3 || this.testItem.type == 4) {
          if (
            this.testItem.studentAnswers[i].chineseStudentAnswer &&
            this.testItem.studentAnswers[i].chineseStudentAnswer.length > 0
          ) {
            //有值说明已经回答过，所以后一个 i + 1是下次应该开始的位置
            this.currentTestingVocIndex = i + 1
            this.moveToNextItemWhenOpen()
            console.log('after type 3 4')
            return
          }
        } else {
          //if (this.testItem.type == 1 || this.testItem.type == 6) {
          if (
            this.testItem.studentAnswers[i].englishStudentAnswer &&
            this.testItem.studentAnswers[i].englishStudentAnswer.length > 0
          ) {
            //有值说明已经回答过，所以后一个 i + 1是下次应该开始的位置
            this.currentTestingVocIndex = i + 1
            this.moveToNextItemWhenOpen()
            console.log('after type 1 6')
            return
          }
        }
      }
    },

    moveToNextItemWhenOpen: function () {
      console.log(this.currentTestingVocIndex)
      //如果不是第一个也不是最后一个，说明不是第一次打开，自动跳转到后面那个词
      if (
        this.currentTestingVocIndex != 0 &&
        this.currentTestingVocIndex != this.testItem.studentAnswers.length - 1
      ) {
        let previousTestVoc =
          this.testItem.studentAnswers[this.currentTestingVocIndex]
        //如果是前面一个为空的情况下move到下一个，应该要提交前一个单词的答案为not answered，以便继续next
        //否则直接关闭下次再打开，因为前一个为空，所以还是会停留在这里
        //而且这样也避免了学生作答的答案里有空值的现象
        this.postVocStudentTestVocabularyAnswer({
          id: previousTestVoc.id,
          listeningCount: previousTestVoc.listeningCount,
          chineseStudentAnswer: 'NOT Answered',
          englishStudentAnswer: 'NOT Answered'
        })
        //post之后把当前测试的词汇move到下一个词汇
        this.currentTestingVocIndex = this.currentTestingVocIndex + 1
      }
    },

    playAudio: function (englishText) {
      let localThis = this
      return new Promise((resolve, reject) => {
        localThis
          .$playAudio(englishText, localThis.$refs.vocaudio)
          .then(() => {
            localThis.testItem.studentAnswers[
              localThis.currentTestingVocIndex
            ].listeningCount -= 1
            resolve()
          })
          .catch(error => {
            reject(error)
          })
      })
    },

    handlePlaySampleSentence: function () {
      //启动暂停倒计时
      this.$refs.vocCountDown.togglePause(true)
      //点击后消失
      this.playSampleSentence = false
      this.playCurrentExampleSentence()
    },

    playCurrentExampleSentence: function () {
      let currentExampleSentences = this.retrieveCurrentExampleSentences()
      //如果拿到的currentExampleSentences 是空的，就直接return，然后resume计时器
      if (!currentExampleSentences) {
        this.$refs.vocCountDown.togglePause(false)
        return
      }

      let currentMultiMeaningIndex =
        this.testItem.studentAnswers[this.currentTestingVocIndex]
          .multiMeaningIndex
      if (
        currentExampleSentences[currentMultiMeaningIndex - 1] &&
        currentExampleSentences[currentMultiMeaningIndex - 1] != 'undefined' &&
        currentExampleSentences[currentMultiMeaningIndex - 1].trim() != ''
      ) {
        var vocAPI = 'http://dict.youdao.com/dictvoice?type=0&audio='
        this.$refs.vocaudio.src =
          vocAPI + currentExampleSentences[currentMultiMeaningIndex - 1]
        this.$refs.vocaudio.play()

        // Event listener to detect when the audio playback has ended
        this.$refs.vocaudio.addEventListener('ended', () => {
          //播放完成后，启动倒计时
          this.$refs.vocCountDown.togglePause(false)
          //focus到中文输入框
          this.$refs.CnInputBox.focus()
        })
      }
    },

    retrieveCurrentExampleSentences: function () {
      let text =
        this.testItem.studentAnswers[this.currentTestingVocIndex].chineseText
      let substr = '例句：'
      //如果chineseText里面没有 "例句：" 就直接返回，或返回空
      if (!text.includes(substr)) return
      let indices = []
      for (const match of text.matchAll(new RegExp(substr, 'g'))) {
        indices.push(match.index + '例句：'.length)
      }
      let result = []
      for (let i = 0; i < indices.length; i++) {
        const start = indices[i]
        const end = text.indexOf('<', start)
        result.push(text.substring(start, end))
      }
      // const start = text.indexOf("例句：") + "例句：".length;
      // const end = text.indexOf("<", start);
      // const result = text.substring(start, end);
      return result
    },

    handleEnglishTextClick: function () {
      if (this.currentTestingVocIndex > 0) {
        this.handleNextClick()
      }
    },

    handleAudioBtnClick: function () {
      if (
        this.testItem.studentAnswers[this.currentTestingVocIndex]
          .listeningCount > 0 ||
        this.testItem.type == 4 ||
        this.testItem.type == 6
      ) {
        //4和6是看写的，不需要播放音频
        if (this.testItem.type != 4 && this.testItem.type != 6) {
          this.playAudio(
            this.testItem.studentAnswers[this.currentTestingVocIndex]
              .englishText
          ).then(() => {
            this.activateCountDown()
          })
        } else {
          this.activateCountDown()
        }
      }
    },

    activateCountDown: function () {
      //单词播放完成后，按情况进入倒计时
      if (!this.test.testInitialized) {
        this.test.isTypingCn = true
        this.test.isTypingEn = false
        //第一遍播放需要设定倒计时
        this.$refs.vocCountDown.countdown(this.initialCountDownSetting, true)
        this.test.testInitialized = true
      }

      //测试状态要嘛是录音，要嘛是拼写；如果是拼写就调用拼写cd，否则用录音cd
      if (this.test.isTypingEn) {
        //可以设置点击发音按钮的时候，重置倒计时到设定的秒数
        //this.$refs.vocCountDown.countdown(this.initialEnTypeCountDownSetting);
        this.$nextTick(() => {})
      } else {
        //this.$refs.vocCountDown.countdown(this.initialCountDownSetting);
        this.$nextTick(() => {
          this.$refs.CnInputBox.focus()
        })
      }
    },

    addMark: function (studentId, vocabulary) {
      this.$store
        .dispatch('Vocabulary/markVocabulary', {
          userId: studentId,
          vocabularyId: vocabulary.vocabularyId,
          count: vocabulary.count + 1,
          marked: true,
          updatedBy: -1
        })
        .then(() => {})
        .catch(error => console.log(error.toString()))
    },

    isSameAsPreMulti: function () {
      //如果不是多义词，就直接return
      if (
        !this.testItem.studentAnswers[this.currentTestingVocIndex]
          .multiMeaningIndex
      )
        return
      //先考虑考虑多义词的情况，如果multiMeaningIndex有值，且大于1，则进入多义词判断，如果和前面写得一样，就判错，直接return，不进去下面的代码块
      if (
        this.testItem.studentAnswers[this.currentTestingVocIndex]
          .multiMeaningIndex &&
        this.testItem.studentAnswers[this.currentTestingVocIndex]
          .multiMeaningIndex > 1
      ) {
        let multiMeaningIndex =
          this.testItem.studentAnswers[this.currentTestingVocIndex]
            .multiMeaningIndex
        for (
          let i = this.currentTestingVocIndex - multiMeaningIndex + 1;
          i < this.currentTestingVocIndex;
          i++
        ) {
          if (
            this.testItem.studentAnswers[this.currentTestingVocIndex]
              .chineseStudentAnswer ==
            this.testItem.studentAnswers[i].chineseStudentAnswer
          ) {
            this.testItem.studentAnswers[
              this.currentTestingVocIndex
            ].isCorrect = false

            return true
          }
        }
      }

      //只有找到与前面多义词一致的答案的时候，return true，并且标记为not correct；
      //循环走完仍然没有return true，说明没找到一样的答案，return false
      return false
    },

    //begin-从AudioTestItemResult平移并调整过的检查audio答案是否正确的函数
    //检查拿到的文本，答案是否正确
    isAnswerCorrect: function (currentVoc) {
      //3和4不用拼写
      if (this.testItem.type != 3 && this.testItem.type != 4) {
        if (
          currentVoc.englishStudentAnswer.trim().toLowerCase() !=
          currentVoc.englishText.trim().toLowerCase()
        ) {
          //拼写错误取消add mark，新规则拼写错误不算mark
          //拼写错误，add mark
          // this.addMark(
          //   this.testItem.studentId,
          //   this.testItem.studentAnswers[this.currentTestingVocIndex]
          // );
          //如果已经错了且追加了标记，就可以return了，不用再进行下面的中文判断
          //添加 注意拼写 的tag; 11是 注意拼写 的标签
          this.tagVoc(currentVoc.vocabularyId, this.testItem.studentId, 11)
          //return;

          //加入spelling
          this.postCommentSpelling(
            this.testItem.studentId,
            currentVoc.vocabularyId,
            currentVoc.englishStudentAnswer.trim().toLowerCase()
          )
        }
      }

      //开始判断中文是否正确
      if ([2, 3, 4].includes(this.testItem.type)) {
        this.isCnAnswerCorrect(currentVoc)
      }
    },

    isCnAnswerCorrect: function (currentVoc) {
      //如果答案为空，则算错;加入NOT Answered
      if (
        !currentVoc.chineseStudentAnswer ||
        currentVoc.chineseStudentAnswer.length == 0 ||
        currentVoc.chineseStudentAnswer == 'NOT Answered'
      ) {
        this.testItem.studentAnswers[
          this.currentTestingVocIndex
        ].isCorrect = false
        //错误答案就标记一下当前单词
        this.addMark(this.testItem.studentId, currentVoc)
        if (
          this.testItem.studentAnswers[this.currentTestingVocIndex]
            .multiMeaningIndex
        ) {
          //如果是多义词错，添加一词多义标签
          this.tagMulti(currentVoc.vocabularyId, this.testItem.studentId)
        }

        return
      }

      if (this.isSameAsPreMulti()) {
        //和前面答案一样了，就标记一下
        this.addMark(this.testItem.studentId, currentVoc)
        //如果是多义词错，添加一词多义标签
        this.tagMulti(currentVoc.vocabularyId, this.testItem.studentId)
        //已经因为相同而被判断为答错，也就直接return，不用再后续判断
        return
      }

      //处理需要匹配的答案数组
      // let originalAudioText = audioTextArray.join("");
      // audioTextArray.push(originalAudioText);
      //进入单词卡“释义”中处理中文文本，匹配答案;然后拼接到要匹配的答案中
      let cnOriginalTextArray = this.generateOriginalCnTextArray(
        currentVoc.chineseText
      )
      currentVoc.correctedAnswers = [
        ...currentVoc.correctedAnswers.map(item => item && item.trim()),
        ...cnOriginalTextArray
      ]

      //过滤掉null，undefined，和空字符串
      currentVoc.correctedAnswers = currentVoc.correctedAnswers.filter(
        item => item != null && item != ''
      )

      //先找corrected answer中的答案做匹配，如果找到匹配的，则为正确，然后return
      let stuAnswerArr = currentVoc.chineseStudentAnswer
        .trim()
        .split(/[,，.。、;；:：?？!！ ]/)
      //把切分前的答案也push进去，让匹配更精确
      stuAnswerArr.push(currentVoc.chineseStudentAnswer.trim())
      //过滤掉null，undefined，和空字符串
      stuAnswerArr.filter(item => item != null && item != '')
      if (
        stuAnswerArr.some(word =>
          currentVoc.correctedAnswers
            .map(item => item && item.trim())
            .includes(word)
        )
      ) {
        currentVoc.cnAnswerResult = true
        return
      }

      // if (
      //   currentVoc.correctedAnswers
      //     .map(item => item && item.trim())
      //     .includes(stuAnswerArr[0] && stuAnswerArr[0].trim())
      // ) {
      //   currentVoc.cnAnswerResult = true
      //   return
      // }

      let pinyinStuCnAnswer = pinyin(currentVoc.chineseStudentAnswer, {
        style: pinyin.STYLE_NORMAL
      }).join('')

      let pinyinAnswersArray = currentVoc.correctedAnswers.map(item =>
        pinyin(item, { style: pinyin.STYLE_NORMAL }).join('')
      )
      if (
        //pinyinAudioTextArray.some((word) => pinyinAnswersArray.includes(word))
        pinyinAnswersArray.includes(pinyinStuCnAnswer)
      ) {
        currentVoc.isCorrect = true
        return
      }

      // if (audioTextArray.some((word) => cnOriginalTextArray.includes(word))) {
      //   this.testItem.studentAnswers[
      //     this.currentTestingVocIndex
      //   ].isCorrect = true;
      //   return;
      // }

      //如果以上的分支都没有return，则此时isCorrecct应该是false，那就追加这个单词的标记
      //此处的addMark函数和普通TestItem的函数不同；这里addMark是在函数里面count+1
      this.addMark(this.testItem.studentId, currentVoc)

      if (
        this.testItem.studentAnswers[this.currentTestingVocIndex]
          .multiMeaningIndex
      ) {
        //如果是多义词错，添加一词多义标签
        this.tagMulti(currentVoc.vocabularyId, this.testItem.studentId)
      }
    },

    //多义词标签，id是1，deleted用于判断是否有这个标签
    tagMulti: function (vocabularyId, studentId) {
      let postData = {
        vocabularyId: vocabularyId,
        userId: studentId,
        vocTagId: 1,
        deleted: false
      }
      this.$store
        .dispatch('Vocabulary/tagVocabulary', postData)
        .then(() => {})
        .catch(error => console.log(error.toString()))
    },

    //新版Naive多了很多中文，比如 释义，例句，备注，需要在新版格式下，找到正确答案，并匹配
    generateOriginalCnTextArray: function (text) {
      let rawCnMeaningArray = []
      if (text.includes('释义：')) {
        rawCnMeaningArray = this.retrieveCnMeaning(text)
      } else {
        rawCnMeaningArray.push(text)
      }

      //let cnTextArray = rawCnMeaning.split(/[,，.。、;；:：?？!！ ]/);
      let resultCnArray = []
      for (let j = 0; j < rawCnMeaningArray.length; j++) {
        let cnTextArray = rawCnMeaningArray[j].split(/[,，.。、;；:：?？!！ ]/)
        for (let i = 0; i < cnTextArray.length; i++) {
          cnTextArray[i] = cnTextArray[i].trim()
          if (this.isChinese(cnTextArray[i])) {
            resultCnArray.push(cnTextArray[i].replace('…', ''))
            if (cnTextArray[i].includes('的'))
              resultCnArray.push(
                cnTextArray[i].replace('…', '').replace('的', '')
              )
            if (cnTextArray[i].includes('地'))
              resultCnArray.push(
                cnTextArray[i].replace('…', '').replace('地', '')
              )
          }
        }
      }

      return resultCnArray
    },

    //从新版Naive中获取中文释义
    retrieveCnMeaning: function (text) {
      let substr = '释义：'
      let indices = []
      for (const match of text.matchAll(new RegExp(substr, 'g'))) {
        indices.push(match.index + '释义：'.length)
      }
      let result = []
      for (let i = 0; i < indices.length; i++) {
        const start = indices[i]
        const end = text.indexOf('<', start)
        result.push(text.substring(start, end))
      }
      return result
    },
    //从新版Naive中获取中文释义

    isChinese: function (text) {
      //以前的regex必须完全匹配中文，包含标点就不算中文；换一个初步测试更合适的regex
      //let re = /^[\u4e00-\u9fa5]+$/;
      let re =
        /[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/g
      if (re.test(text)) return true
      return false
    },
    //end-从AudioTestItemResult平移并调整过的检查audio答案是否正确的函数

    postVocStudentTestVocabularyAnswer: function (postData) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('StudentTest/editVocStudentTestVocabularyAnswer', postData)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            this.$message('词汇未提交，请检查网络是否稳定')
            reject(error)
          })
      })
    },

    //为单词添加特定标签
    tagVoc: function (vocabularyId, studentId, vocTagId) {
      let postData = {
        vocabularyId: vocabularyId,
        userId: studentId,
        vocTagId: vocTagId,
        deleted: false
      }
      this.$store
        .dispatch('Vocabulary/tagVocabulary', postData)
        .then(() => {})
        .catch(error => console.log(error.toString()))
    },

    //如果拼写错误，就把错误的拼写加入comment中的spelling
    postCommentSpelling: function (userId, vocabularyId, spelling) {
      this.$store.dispatch('Vocabulary/commentVocabulary', {
        userId: userId,
        vocabularyId: vocabularyId,
        spelling: spelling
      })
    },

    // printText: function (text) {
    //   if (!text) return;
    //   //let textArray = text.split('');
    // },

    // //每0.1秒输出一个字符，直到输出完完整内容
    // printingInterval: function (str) {
    //   let index = 0;
    //   const intervalId = setInterval(() => {
    //     //console.log(str.substr(0, index + 1));
    //     this.displayingAudioText = str.substr(0, index + 1);
    //     index++;
    //     if (index === str.length) {
    //       clearInterval(intervalId);
    //     }
    //   }, 100);
    // },

    handleCountDownFinish: function () {
      //倒计时结束先进入下个单词；进入之前需要录音识别和答案提交
      //录音停止，提交答案、to be continued

      //进入下个单词
      this.handleNextClick()
    },

    enterTypeEn: function () {
      //if (this.initialEnTypeCountDownSetting == 0) this.processNextAudioItem();
      if (!this.test.isTypingEn && this.initialEnTypeCountDownSetting > 0) {
        //如果mode是包含拼写的，且isTypingEn是false，就不进入下一个单词，而是进入拼写倒计时，再来一次countdown
        this.test.isTypingEn = true
        this.test.isTypingCn = false
        this.$refs.vocCountDown.countdown(
          this.initialEnTypeCountDownSetting,
          true
        )
        //显示拼写的inputbox... 获得焦点(nextTick保证在dom内容加载完成后执行focus)
        this.$nextTick(() => {})
      } else {
        this.processNextAudioItem()
        this.test.isTypingEn = false
        // update english answer
        //reset currentEnType
        this.test.currentEnType = ''
      }
    },

    handleNextClick: function () {
      this.implementNextBtnClick()
      //下一个单词的时候，重置playSampleSentence
      this.playSampleSentence = true
    },

    implementNextBtnClick: function () {
      this.enterTypeEn()
    },

    postAnswerBeforeEnteringNext: function (currentTestVoc) {
      //更新学生作答 (如果没作答，就给NOT ANSWERED 以便系统识别成已经回答过了)
      currentTestVoc.englishStudentAnswer =
        this.test.currentEnType || 'NOT Answered'
      currentTestVoc.chineseStudentAnswer =
        this.test.currentCnType || 'NOT Answered'

      //清空inputbox
      this.test.currentCnType = ''
      this.test.currentEnType = ''

      //进入下一个单词之前，判断当前的答案对不对，然后给mark
      //赋值currentTestVoc之前判定isCorrect的值
      this.isAnswerCorrect(currentTestVoc)

      //如果这个词是多义词的至少第二个意思开始，结合上一个多义词的意思，一起更新
      //第一个意思的index是1，大于1说明至少是第二个意思
      if (
        currentTestVoc.multiMeaningIndex &&
        currentTestVoc.multiMeaningIndex > 1
      ) {
        //处理多义词的临时更新
        let preVoc =
          this.testItem.studentAnswers[this.currentTestingVocIndex - 1]
        //如果多义词的第一个意思有cache，就直接启用，否则再按正常逻辑走
        let multiVocFirst =
          this.testItem.studentAnswers[
            this.currentTestingVocIndex - currentTestVoc.multiMeaningIndex + 1
          ]

        if (
          multiVocFirst.cachedChineseStudentAnswer &&
          multiVocFirst.cachedChineseStudentAnswer.length > 0
        ) {
          currentTestVoc.chineseStudentAnswer =
            multiVocFirst.cachedChineseStudentAnswer +
            '&' +
            currentTestVoc.chineseStudentAnswer

          //使用完缓存后，把缓存清空
          multiVocFirst.cachedChineseStudentAnswer = null
        } else {
          currentTestVoc.chineseStudentAnswer =
            preVoc.chineseStudentAnswer +
            '&' +
            currentTestVoc.chineseStudentAnswer
        }

        //多义词更新的时候，如果答错了，给多义词标签
        //该标签应该不允许取消，因为如果全对就取消，可能会取消掉以前多义词的标签
        // if (!currentTestVoc.isCorrect) {
        //   this.tagVoc(currentTestVoc.vocabularyId, this.testItem.studentId);
        // }
      }

      this.postVocStudentTestVocabularyAnswer({
        id: currentTestVoc.id,
        listeningCount: currentTestVoc.listeningCount,
        chineseStudentAnswer: currentTestVoc.chineseStudentAnswer,
        englishStudentAnswer: currentTestVoc.englishStudentAnswer
      })
    },

    processNextAudioItem: function () {
      this.test.isTypingCn = true
      this.test.isTypingEn = false
      //进入下一个单词之前，提交当前单词的答案
      //当前的词汇
      let currentTestVoc =
        this.testItem.studentAnswers[this.currentTestingVocIndex]
      this.postAnswerBeforeEnteringNext(currentTestVoc)
      //englishStudentAnswer

      //如果不是最后一个词，就进入下一个单词，否则submit提交测试，测试结束

      if (
        this.currentTestingVocIndex <
        this.testItem.studentAnswers.length - 1
      ) {
        //进入下一个单词
        this.currentTestingVocIndex += 1
        //进入下一个单词之后，先清除countdown，避免可能的bug
        this.$refs.vocCountDown.countdown(0, false)

        //进入下一个单词后，直接提交当前单词的中英文NOT answered，防止学生关掉重开后，停留在这个单词
        //逻辑是：进入当前单词后，自动提交一次，避免学生每次关掉想单词
        //先取消这个提交，这个提交可能会导致bug
        //this.postAnswerBeforeEnteringNext(currentTestVoc);

        //如果有hint，比如同音词hint，就给出提示
        if (this.testItem.studentAnswers[this.currentTestingVocIndex].hint)
          this.openVocHintNotice(
            this.testItem.studentAnswers[this.currentTestingVocIndex].hint
          )

        //先自动播放一次音频
        //this.handleAudioBtnClick(); //这个函数不是promise，所以next自动播放的音频就无法做到播放完音频再计时
        //4和6是看写的，不需要播放音频
        if (this.testItem.type != 4 && this.testItem.type != 6) {
          this.playAudio(
            this.testItem.studentAnswers[this.currentTestingVocIndex]
              .englishText
          ).then(() => {
            this.processNextAudioItemAfterPlaying()
          })
        } else {
          this.processNextAudioItemAfterPlaying()
        }

        //this.$refs.vocCountDown.countdown(this.initialCountDownSetting);

        // if (this.test.mode == 1 || this.test.mode == 2) {
        //   //重置语音倒计时
        //   this.$refs.vocCountDown.countdown(this.initialCountDownSetting);
        //   //自动点击录制按钮
        //   //this.$refs.audioToTextRecorder.handleRecordingBtnClick();
        // } else {
        //   //重置拼写倒计时; 先重制成语音的倒计时0，以便跳过语音部分
        //   this.$refs.vocCountDown.countdown(this.initialCountDownSetting);
        // }
      } else {
        this.submit()
        // if (this.test.mode == 1) {
        //   //更新测试状态，主要是finished
        //   this.submit();
        // } else if (this.test.mode == 2 || this.test.mode == 3) {
        //   if (!this.test.isTypingEn) {
        //     //如果mode是包含拼写的，且isTypingEn是false，就不进入下一个单词，而是进入拼写倒计时，再来一次countdown
        //     this.test.isTypingEn = true;
        //     this.$refs.vocCountDown.countdown(
        //       this.initialEnTypeCountDownSetting
        //     );
        //     //显示拼写的inputbox...
        //   } else {
        //     this.submit();
        //     this.test.isTypingEn = false;
        //   }
        // }
      }
    },

    //4和6是看的，不用播放音频
    processNextAudioItemAfterPlaying: function () {
      //测试状态要嘛是录音，要嘛是拼写；如果是拼写就调用拼写cd，否则用录音cd
      if (this.test.isTypingEn) {
        //可以设置点击发音按钮的时候，重置倒计时到设定的秒数
        this.$refs.vocCountDown.countdown(
          this.initialEnTypeCountDownSetting,
          true
        )
        this.$nextTick(() => {})
      } else {
        this.$refs.vocCountDown.countdown(this.initialCountDownSetting, true)
        this.$nextTick(() => {
          this.$refs.CnInputBox.focus()

          //当前的词汇
          let currentTestVoc =
            this.testItem.studentAnswers[this.currentTestingVocIndex]
          //如果这个词是多义词的至少第二个意思开始，结合上一个多义词的意思，一起更新
          //第一个意思的index是1，大于1说明至少是第二个意思
          if (
            currentTestVoc.multiMeaningIndex &&
            currentTestVoc.multiMeaningIndex > 1
          ) {
            //处理多义词的临时更新
            let preVoc =
              this.testItem.studentAnswers[this.currentTestingVocIndex - 1]
            //更新一下多义词的英文拼写，使其等于第一个多义词学生输入的英文
            this.test.currentEnType = preVoc.englishStudentAnswer
          }
        })
      }
    },

    submit: function () {
      //加入submitted，以便跳转的result页面可以知道是从前一个test页面submit过来的
      this.decodedQueryObject.submitted = true

      this.finalUpdateTestStatus()
    },

    finalUpdateTestStatus: function () {
      //点击submit的时候，先提交一个overview，拿到overview id，然后再处理后续的student test status以及答案的校验
      this.addStudentOverview()
        .then(() => {
          this.$store
            .dispatch('StudentTest/updateStudentTestStatus', {
              id: this.testItem.id,
              studentId: this.testItem.studentId,
              name: this.testItem.name,
              type: this.testItem.type,
              origin: this.testItem.origin,
              nirvanaCount: 0,
              description: this.testItem.description || '',
              status: 'finished',
              published: true
            })
            .then(response => {
              if (response.data != -1) this.submitted = true
              this.OpenInCurrentPageWithCryption(
                '/newtestitemresult',
                this.decodedQueryObject
              )
              //测试结束，传入当前测试testItem的对象，进入result页面
              // setTimeout(() => {
              //   this.OpenInCurrentPageWithCryption(
              //     "/audiotestitemresult",
              //     this.decodedQueryObject
              //   );
              // }, 3000);
              // console.log("进入结果页面")
              // this.OpenInCurrentPageWithCryption(
              //     "/audiotestitemresult",
              //     this.decodedQueryObject
              //   );
            })
            .catch(error => this.$message(error.toString()))
        })
        .catch(error => this.$message(error.toString()))
    },

    addStudentOverview: function () {
      return new Promise((resolve, reject) => {
        let postData = {
          studentId: this.testItem.studentId,
          vocTestId: this.testItem.id,
          learningMode: '语音测试',
          checkingMode: this.testItem.type.toString(),
          vocSetNames: this.decodedQueryObject.origin
        }

        this.$store
          .dispatch('StudentOverview/addVocStudentOverview', postData)
          .then(response => {
            if (response.data != -1) {
              this.currentOverviewId = response.data
              resolve(response)
            }
          })
          .catch(error => {
            console.log(error.toString())
            reject(error)
          })
      })
    },

    addStudentOverviewDetail: function () {
      for (let i = 0; i < this.testItem.studentAnswers.length; i++) {
        this.upsertVocStudentOverviewDetail(
          this.currentOverviewId,
          this.testItem.studentAnswers[i].vocabularyId,
          this.testItem.studentAnswers[i].isCorrect
        )
      }
    },

    OpenInCurrentPageWithCryption: function (routeName, query) {
      query = JSON.stringify(query)
      query = window.encodeURIComponent(query)
      this.$router.push({
        path: routeName,
        query: { encodedString: window.btoa(query) }
      })
    },

    // handleInputAnswerKeydown: function (key) {
    //   //如果输入框中表示的是中文输入法，那就直接blur
    //   //取消key的监视，这里中文输入法keycode大于193，但不是准确数值，如果之后有bug，需要监控key来观察原因

    //   if (
    //     key.keyCode > 193 &&
    //     key.code.includes("Key") &&
    //     key.code.replace("Key", "").length == 1
    //   ) {
    //     //获得el-input下的原生input的selectionStart下标位置
    //     // let inputIndex = this.$refs.EnInputBox.$refs.input.selectionStart;

    //     // let keyChar = key.code.replace("Key", "").toLowerCase();

    //     // Dispatch the modified event
    //     //document.dispatchEvent(key);
    //     if (this.$refs.EnInputBox) this.$refs.EnInputBox.blur();
    //     // setTimeout(() => {
    //     //
    //     //   if (keyChar.length === 1) {
    //     //     this.test.currentEnType =
    //     //       this.test.currentEnType.slice(0, inputIndex) +
    //     //       keyChar +
    //     //       this.test.currentEnType.slice(inputIndex);
    //     //   }
    //     // }, 1);
    //   }
    // },

    handleNewTestKeyDown: function (key) {
      //如果页面还没加载出enType的输入框，就不更新内容
      if (!this.$refs.EnInputBox) return
      //如果是获得焦点的状态，那就不改变输入的内容
      // console.log(this.test.enActive);
      // if (this.test.enActive) return;
      //if (key.keyCode > 193) {
      //获得el-input下的原生input的selectionStart下标位置

      // let inputIndex;
      // if (this.$refs.EnInputBox)
      //   inputIndex = this.$refs.EnInputBox.$refs.input.selectionStart;
      if (key.code == 'Space') {
        this.test.currentEnType += ' '
      }

      if (key.code == 'Backspace') {
        this.test.currentEnType = this.test.currentEnType.slice(0, -1)
      }

      let keyChar = key.code.replace('Key', '').toLowerCase()

      // Dispatch the modified event
      //document.dispatchEvent(key);

      //this.$refs.EnInputBox.blur();
      //setTimeout(() => {

      if (keyChar.length === 1) {
        // this.test.currentEnType =
        //   this.test.currentEnType.slice(0, inputIndex) +
        //   keyChar +
        //   this.test.currentEnType.slice(inputIndex);
        this.test.currentEnType += keyChar
      }
      //
      //}, 10000);
      //}
    },

    openVocHintNotice: function (hint) {
      ElNotification.success({
        title: '提示',
        message: hint,
        offset: 200
      })
    },

    handleEnTypeFocus: function () {
      this.test.enTypeDisabled = true
      this.$refs.EnInputBox.blur()
    },

    refocusCnInput: function () {
      // Refocus the input element after a short delay
      setTimeout(() => {
        this.$refs.CnInputBox.focus()
      }, 0)
    },

    onRightClickEnglishText: function () {
      //这里似乎检测到右键，就会组织进行进一步事件了，所以不再需要refocus了
      //但是这里似乎还可以借助右键做更多事情
      //暂时先不进行处理
      console.log('right click')
    },

    //当用户离开当前浏览页面且测试不在第一个单词（说明已经开始测试）的时候，提交单词
    handleVisibilityChange: function () {
      // if (document.visibilityState === "visible") {
      //   console.log("用户正在浏览当前页面");
      //   // 在这里可以添加相应的逻辑，比如恢复页面中的活动
      // } else {
      //   console.log("用户已经离开当前页面");
      //   // 在这里可以添加相应的逻辑，比如暂停页面中的活动
      // }
      //
      if (document.hidden && this.currentTestingVocIndex > 0) {
        this.$message('检测到用户离开当前页面，已自动提交当前词汇的答案')
        this.handleNextClick()
      }
    },

    handleMouseMove: function (event) {
      //如果鼠标的左右，左边靠近page边界，右边靠近page边界的时候，自动退出
      if (
        event.clientX >
          document.querySelector('#outsideContainer').offsetWidth - 20 ||
        event.clientX < 20
      ) {
        // User is trying to interact outside the focused page
        this.$message('leaving')
        window.close()
        // You can show a warning message or perform other actions here
      }
    },

    //鼠标up的时候，如果选中的词是当前英文单词，则判定为要复制查词，直接跳到下个词
    handleDocumentMouseUp: function () {
      if (
        this.testItem.studentAnswers[this.currentTestingVocIndex]
          .englishText === window.getSelection().toString()
      ) {
        this.handleNextClick()
      }
    },

    handleAudioTestKeyUp: function (event) {
      //回车，提前当前回答
      switch (event.keyCode) {
        case 13:
          //进入下个单词
          this.handleNextClick()
          break
      }
    },

    onInputCnEnter (event) {
      //如果中文输入框里面没有东西，就stopPropagation，如果有东西，就正常进入下一个单词
      if (this.test.currentCnType.length <= 0) {
        // Prevent the event from reaching the global enter event listener
        event.stopPropagation()
      }
    }

    // handleBeforeUnload(event) {
    //   //退出之前，直接触发process next，模拟进入下一个单词，为了提交当前单词，以便下次打开的时候从下个词开始
    //   //当前的词汇
    //   let currentTestVoc =
    //     this.testItem.studentAnswers[this.currentTestingVocIndex];
    //   this.postAnswerBeforeEnteringNext(currentTestVoc);
    //   // This method will be called when the user is about to leave the page.
    //   // You can trigger actions or post data to the server here.
    //   // const message = "这个消息不会被浏览器采用～";
    //   //event.returnValue = message; // Standard for most browsers
    //   // return event.message; // For some older browsers
    //   console.log(event.toString());
    // },
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
div#outsideContainer {
  font: bold 24pt sans;
  color: deepskyblue;
}

.test-progress {
  font: 500 18pt sans;
}

.card-wrapper {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  margin-top: 10%;
  justify-content: center;
  align-items: center;
  align-content: center;
}

.card-wrapper > * {
  flex: 1 100%;
}

.card-content {
  border-radius: 5px 5px 0 0;
  margin-left: 19%;
  margin-right: 19%;
  background-color: white;
  box-shadow: 0 0.25rem 1rem 0 rgba(48, 53, 69, 0.08);
  height: 400px;
}

.card-items {
  margin-top: 1.5rem;
}

.next-btn {
  margin-top: 2rem;
}

.test-audio-icon {
  cursor: pointer;
  color: orange;
}

.test-item {
  margin-top: 2rem;
}

.count-down-text {
  font: bold 28pt sans;
}

.test-listening-count {
  font: 100 12pt sans;
}

.audio-text {
  color: rgb(189, 122, 246, 0.8);
  font: 600 20pt sans;
}

.custom-alert {
  border-radius: 0.3rem;
  padding: 8px;
  margin-bottom: 15px;
  text-align: left;
}

.custom-alert span {
  padding-left: 10px;
}

.custom-alert-title {
  border-radius: 0.3rem;
  background-color: #f3d19e;
  padding: 8px;
  margin-bottom: 15px;
  text-align: center;
}

.custom-alert-warning {
  border-radius: 0.3rem;
  padding: 8px;
  margin-bottom: 15px;
  text-align: left;
  color: red;
}
</style>
