import { createStore } from "vuex";
import Login from "./login";
import Set from "./set";
import Project from "./project";
import Subject from "./subject";
import SubjectSet from "./subjectSet";
import Vocabulary from "./vocabulary";
import Admin from "./admin";
import StudentViewingHistory from "./viewHistory";
import StudentTest from "./studentTest";
import TestCorrectedAnswer from "./testCorrectedAnswer";
import VIP from "./vip";
import StudentOverview from "./studentOverview";
import Recording from "./recording";
import StudentPlan from "./studentPlan";
import Grade from "./grade";
import Elsa from "./elsa"
import DeepSeek from "./deepseek"

export default createStore({
  modules: {
    Login,
    Set,
    Project,
    Subject,
    SubjectSet,
    Vocabulary,
    Admin,
    StudentViewingHistory,
    StudentTest,
    TestCorrectedAnswer,
    VIP,
    StudentOverview,
    Recording,
    StudentPlan,
    Grade,
    Elsa,
    DeepSeek
  },
});
