import axios from "axios";

const deepseekservice = axios.create({
  withCredentials: false,
});

export default {
  //post
  getDeepSeekResponse(context, data) {
    return new Promise((resolve, reject) => {
      //百炼url
      let postUrl = "https://dashscope.aliyuncs.com/compatible-mode/v1/chat/completions";
      //deepseek url
      //let postUrl = "https://api.deepseek.com/chat/completions"
      deepseekservice
        .post(postUrl, data, {
          // 百炼 api key
          headers: {
            Authorization: `Bearer sk-abd4125d325943eba8a2db782d62b4c0`
          }
          // deepseek api key
          // headers: {
          //   Authorization: `Bearer sk-8c3af2fb15984b6890c56abf499178fd`
          // }
        })
        .then((response) => {
          console.log(response)
          resolve(response);
        })
        .catch((error) => reject(error));
    });
  },
};
