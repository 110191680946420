<template>
  <div id="outsideContainer" class="border">
    <div class="set-location card-header">
      <div class="inside-header-left">
        <span class="andover-logo-area" @click="gotoHome"
          ><img style="width: 20px; height: 20px" src="./andover.png"
        /></span>
      </div>
      <div class="navi-text">
        <div class="clickPointer" @click="handleTotalCountClick">
          {{ vocCardItem.currentVocIndex + 1 }} / {{ vocCardItem.vocCount }}
        </div>
        <div v-if="!vocCardItem.loaded">
          {{ vocCardItem.setStarting }} / {{ vocCardItem.setsTotalCount }}
        </div>
        <div>
          <span
            ><el-popover placement="bottom" :width="400" trigger="hover">
              <template #reference>{{
                decodedQueryObject.projectName
              }}</template>
              <el-table
                :data="currentSubjects"
                stripe
                @row-click="handleSubjectRowClick"
              >
                <el-table-column property="name" label="当前科目" />
              </el-table> </el-popover
          ></span>
          <span> > </span>
          <span>{{ decodedQueryObject.subjectName }}</span>
          <span> > </span>
          <el-tooltip
            class="box-item"
            effect="dark"
            :content="decodedQueryObject.setName"
            placement="bottom-start"
          >
            <span class="clickPointer" @click="handleShowVocListEditDrawer">{{
              decodedQueryObject.setIds.length == 1
                ? decodedQueryObject.setName
                : '多个词汇集'
            }}</span>
          </el-tooltip>

          <!-- <span class="clickPointer" @click="handleShowVocListEditDrawer">{{
            decodedQueryObject.setIds.length == 1
              ? decodedQueryObject.setName
              : "多个词汇集"
          }}</span> -->
        </div>
      </div>

      <div class="inside-header-right">
        <!-- <div
          v-if="
            isPlanDetailTask &&
            (userRole != 'Student' || currentTask.isExaminingAsStudent)
          "
        > -->
        <div class="help">
          <el-button @click="handleAddIncorrectWords">错词</el-button>
        </div>

        <div v-if="isPlanDetailTask" class="help">
          <el-button @click="handleAddTaskResultClick">添加</el-button>
        </div>
        <div class="help">
          <el-button
            @click="showManipulatingProcedure = !showManipulatingProcedure"
            >步骤</el-button
          >
        </div>
        <div class="help">
          <el-button @click="copyIncorrectsToClip()">小抄</el-button>
        </div>
        <div class="help">
          <el-button @click="handleAutoPlay()">{{
            activateAutoPlay ? '停止' : '播放'
          }}</el-button>
        </div>
        <div class="help">
          <el-button @click="handleHelp()">帮助</el-button>
        </div>
        <div class="tools-option">
          <el-button @click="handlePlayVocVideo()">秘籍</el-button>
        </div>
        <div @click="handleRefreshPage()" class="refresh-icon">
          <el-button
            ><el-icon><RefreshRight /></el-icon
          ></el-button>
        </div>
      </div>
    </div>

    <el-steps simple v-if="showManipulatingProcedure">
      <el-step
        v-for="(item, index) in manipulatingProcedure"
        :key="index"
        :title="item"
        @click="handleProcedureStepClick(item, index)"
      />
    </el-steps>

    <div class="card-wrapper" v-if="vocList.length > 0">
      <div class="card-content">
        <header>
          <div class="header-container">
            <div class="heart-container">
              <div style="margin-top: 2px">
                <div>
                  <span
                    class="mark-heart"
                    :class="[
                      'iconfont',
                      'heart',
                      vocList[vocCardItem.currentVocIndex].marked
                        ? 'icon-marked'
                        : 'icon-unmarked'
                    ]"
                    @click="handleVocMarkClick(1)"
                    :style="
                      vocList[vocCardItem.currentVocIndex].marked &&
                      vocList[vocCardItem.currentVocIndex].count > 5
                        ? 'color: purple'
                        : ''
                    "
                  ></span>
                  <span
                    class="mark-heart"
                    :class="[
                      'iconfont',
                      'heart',
                      vocList[vocCardItem.currentVocIndex].marked &&
                      vocList[vocCardItem.currentVocIndex].count > 1
                        ? 'icon-marked'
                        : 'icon-unmarked'
                    ]"
                    @click="handleVocMarkClick(2)"
                    :style="
                      vocList[vocCardItem.currentVocIndex].marked &&
                      vocList[vocCardItem.currentVocIndex].count > 5
                        ? 'color: purple'
                        : ''
                    "
                  ></span>
                  <span
                    class="mark-heart"
                    :class="[
                      'iconfont',
                      'heart',
                      vocList[vocCardItem.currentVocIndex].marked &&
                      vocList[vocCardItem.currentVocIndex].count > 2
                        ? 'icon-marked'
                        : 'icon-unmarked'
                    ]"
                    @click="handleVocMarkClick(3)"
                    :style="
                      vocList[vocCardItem.currentVocIndex].marked &&
                      vocList[vocCardItem.currentVocIndex].count > 5
                        ? 'color: purple'
                        : ''
                    "
                  ></span>
                  <span
                    class="mark-heart"
                    :class="[
                      'iconfont',
                      'heart',
                      vocList[vocCardItem.currentVocIndex].marked &&
                      vocList[vocCardItem.currentVocIndex].count > 3
                        ? 'icon-marked'
                        : 'icon-unmarked'
                    ]"
                    @click="handleVocMarkClick(4)"
                    :style="
                      vocList[vocCardItem.currentVocIndex].marked &&
                      vocList[vocCardItem.currentVocIndex].count > 5
                        ? 'color: purple'
                        : ''
                    "
                  ></span>
                  <span
                    class="mark-heart"
                    :class="[
                      'iconfont',
                      'heart',
                      vocList[vocCardItem.currentVocIndex].marked &&
                      vocList[vocCardItem.currentVocIndex].count > 4
                        ? 'icon-marked'
                        : 'icon-unmarked'
                    ]"
                    @click="handleVocMarkClick(5)"
                    :style="
                      vocList[vocCardItem.currentVocIndex].marked &&
                      vocList[vocCardItem.currentVocIndex].count > 5
                        ? 'color: purple'
                        : ''
                    "
                  ></span>
                </div>
              </div>
              <div
                class="heart-and-tools"
                @click="handleAudioIconClick()"
                style="margin-top: 2px"
              >
                <span
                  :class="[
                    'iconfont',
                    toolConfig.audio ? 'icon-voc-audio' : 'icon-mute'
                  ]"
                ></span>
              </div>

              <div
                class="heart-and-tools"
                v-if="isAllowedEditOrDeleteVoc"
                @click="handleEditCurrentWord()"
              >
                <el-icon><edit-pen /></el-icon>
              </div>

              <div class="heart-and-tools" @click="handleTools()">
                <el-icon><tools /></el-icon>
              </div>

              <div class="heart-and-tools" @click="handleDateFilter()">
                <el-icon><Timer /></el-icon>
              </div>

              <div
                class="heart-and-tools"
                @click="shuffleVoc()"
                style="margin-top: 4px"
              >
                <span class="iconfont icon-random"></span>
              </div>

              <div class="heart-and-tools" @click="showVocExportDrawer = true">
                <el-icon><UploadFilled /></el-icon>
              </div>

              <div
                v-if="
                  vocList[vocCardItem.currentVocIndex].imagePath != null &&
                  vocList[vocCardItem.currentVocIndex].imagePath != ''
                "
                class="heart-and-tools"
                style="margin-top: 2px"
                @click="showImage = !showImage"
              >
                <span class="iconfont icon-image"></span>
              </div>

              <div
                class="heart-and-tools"
                v-if="userRole != 'Student'"
                @click="showCreateTestForm = true"
              >
                <el-icon><Tickets /></el-icon>
              </div>

              <!-- <div
                class="heart-and-tools"
                @click="handleCheckingModeClick(1)"
                style="margin-top: 2px"
              >
                <span class="iconfont icon-en"></span>
              </div>

              <div
                class="heart-and-tools"
                @click="handleCheckingModeClick(2)"
                style="margin-top: 2px"
              >
                <span class="iconfont icon-cn"></span>
              </div>

              <div
                class="heart-and-tools"
                @click="handleCheckingModeClick(4)"
                style="margin-top: 4px"
              >
                <el-icon><FullScreen /></el-icon>
              </div> -->
              <div
                class="heart-and-tools"
                @click="handleClickAIShortcut()"
                style="margin-top: 4px"
              >
                <el-icon><Present /></el-icon>
              </div>

              <el-tooltip class="box-item" effect="dark" placement="bottom">
                <template #content>开关音节显示</template>
                <div
                  class="heart-and-tools"
                  :class="showSyllables ? '' : 'hide'"
                  @click="showSyllables = !showSyllables"
                >
                  <el-icon><MoreFilled /></el-icon>
                </div>
              </el-tooltip>

              <div>
                <div
                  class="heart-and-tools checking-mode-switch"
                  @click="handleChangeCheckingModeClick()"
                  style="font-size: 0.8rem"
                >
                  <span
                    :class="[1, 3].includes(toolConfig.answer) ? '' : 'hide'"
                    >en</span
                  ><span> / </span
                  ><span
                    :class="[2, 3].includes(toolConfig.answer) ? '' : 'hide'"
                    >cn</span
                  >
                </div>
              </div>
            </div>
            <div class="student-mark">
              <el-tooltip class="box-item" effect="dark" placement="bottom">
                <template #content
                  >该词匹配Evian Naive（如果是TPO和UFO词汇题则匹配机经词频）
                  <br />
                  红色是在Evian Naive（机经）中标记过的
                  <br />蓝色是未标记的</template
                >
                <span
                  v-if="vocList[vocCardItem.currentVocIndex].mappingWord"
                  :class="
                    vocList[vocCardItem.currentVocIndex].mappingWord.marked
                      ? 'red'
                      : 'deepskyblue'
                  "
                  >Match
                </span>
              </el-tooltip>

              <el-tooltip class="box-item" effect="dark" placement="bottom">
                <template #content>过滤专属星标(s)</template>
                <span
                  class="mark-heart iconfont heart icon-marked-outdated"
                  @click.stop="filterStudentMarkedVoc"
                ></span>
              </el-tooltip>

              <el-tooltip class="box-item" effect="dark" placement="bottom">
                <template #content
                  >按 m 标记该词 <br />
                  按 s 过滤专属星标</template
                >
                <span
                  class="mark-heart"
                  :class="[
                    'iconfont',
                    'heart',
                    vocList[vocCardItem.currentVocIndex].studentMarked
                      ? 'icon-marked-outdated'
                      : 'icon-unmarked-outdated'
                  ]"
                  @click.stop="handleStudentMarkClick"
                ></span>
              </el-tooltip>
            </div>
          </div>
        </header>
        <el-row>
          <el-col :span="2" class="controller-left">
            <div @click.stop="handlePrevClick()">
              <el-icon><ArrowLeftBold /></el-icon>
            </div>
          </el-col>
          <el-col :span="20">
            <article
              id="scriptContainer"
              @click="handleVocClick"
              @touchstart="touchStart"
              @touchmove="touchMove"
              @touchend="touchEnd"
              v-show="!showEnCn"
              v-if="vocList.length > 0"
            >
              <div
                style="
                  position: relative;
                  min-height: 280px;
                  padding-top: 130px;
                "
              >
                <transition name="el-zoom-in-center">
                  <div id="EnScriptContainer" v-show="!showMeaning && showEn">
                    <div class="EnText">
                      <el-badge
                        :value="vocCardItem.currentVocLevel"
                        :hidden="!vocCardItem.currentVocLevel"
                        class="item"
                        type="primary"
                      >
                        <el-tooltip
                          class="box-item"
                          effect="dark"
                          :content="
                            vocList[vocCardItem.currentVocIndex].studentComent
                          "
                          placement="top"
                        >
                          <p
                            @click.stop="
                              handleTextClick(
                                vocList[vocCardItem.currentVocIndex].englishText
                              )
                            "
                            v-if="!showCommentInputbox"
                          >
                            <el-badge
                              is-dot
                              :hidden="
                                !vocList[vocCardItem.currentVocIndex]
                                  .teacherComment &&
                                !vocList[vocCardItem.currentVocIndex]
                                  .studentComent
                              "
                            >
                              <span
                                v-if="
                                  showSyllables &&
                                  vocList[vocCardItem.currentVocIndex].syllables
                                "
                                class="deepskyblue"
                              >
                                {{
                                  vocList[vocCardItem.currentVocIndex].syllables
                                }}
                                <br />
                              </span>

                              <span>
                                {{
                                  vocList[vocCardItem.currentVocIndex]
                                    .englishText
                                }}
                              </span>
                            </el-badge>
                          </p>

                          <div v-else>
                            <el-input
                              ref="vocabularyCommentInputBoxEn"
                              class="mt-4"
                              style="30%"
                              v-model="vocabularyComment"
                              placeholder="评论区"
                              @keyup.enter="handleEnterOnCommentInputbox"
                              @click.stop
                              @keydown.stop
                              @keypress.stop
                              @keyup.stop
                            ></el-input>
                          </div>
                        </el-tooltip>
                      </el-badge>
                    </div>
                    <div
                      v-if="
                        vocList[vocCardItem.currentVocIndex].comparedSpelling
                      "
                      class="SpellingText"
                    >
                      <span
                        v-for="(char, index) in vocList[
                          vocCardItem.currentVocIndex
                        ].comparedSpelling"
                        :key="index"
                        :class="char.errorType"
                      >
                        {{ char.char }}
                      </span>
                    </div>
                  </div>
                </transition>
                <div id="CnScriptContainer" v-show="showMeaning">
                  <!-- <div
                    class="image"
                    v-if="
                      vocList[vocCardItem.currentVocIndex].imagePath != null &&
                      vocList[vocCardItem.currentVocIndex].imagePath != ''
                    "
                  >
                    <el-image
                      :src="
                        '../../../vocabulary/images/' +
                        vocList[vocCardItem.currentVocIndex].imagePath
                      "
                    ></el-image>
                  </div> -->

                  <div class="CnText">
                    <p
                      @click.stop="handleTextClick()"
                      v-html="vocList[vocCardItem.currentVocIndex].chineseText"
                    ></p>
                  </div>
                </div>
              </div>
            </article>
            <!-- </div> -->

            <article
              id="showEnCn"
              v-show="showEnCn"
              v-if="vocList.length > 0"
              @click="handleVocClick"
            >
              <transition name="el-zoom-in-center">
                <div v-show="!showMeaning && showEn">
                  <div>
                    <div class="EnCnText EnText">
                      <span
                        v-if="
                          showSyllables &&
                          vocList[vocCardItem.currentVocIndex].syllables
                        "
                        class="deepskyblue"
                      >
                        {{ vocList[vocCardItem.currentVocIndex].syllables }}
                      </span>
                      <p
                        @click.stop="
                          handleTextClick(
                            vocList[vocCardItem.currentVocIndex].englishText
                          )
                        "
                      >
                        {{ vocList[vocCardItem.currentVocIndex].englishText }}
                      </p>
                    </div>
                  </div>
                  <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
                  <div>
                    <div class="EnCnText CnText">
                      <p
                        @click.stop="handleTextClick()"
                        v-html="
                          vocList[vocCardItem.currentVocIndex].chineseText
                        "
                      ></p>
                    </div>
                  </div>
                </div>
              </transition>
              <!-- <transition name="el-zoom-in-center"> -->
              <div v-show="showMeaning">
                <div>
                  <div class="EnCnText EnText">
                    <span
                      v-if="
                        showSyllables &&
                        vocList[vocCardItem.currentVocIndex].syllables
                      "
                      class="deepskyblue"
                    >
                      {{ vocList[vocCardItem.currentVocIndex].syllables }}
                      <br />
                    </span>
                    <p
                      @click.stop="
                        handleTextClick(
                          vocList[vocCardItem.currentVocIndex].englishText
                        )
                      "
                      v-if="!showCommentInputbox"
                    >
                      {{ vocList[vocCardItem.currentVocIndex].englishText }}
                    </p>
                    <div v-else>
                      <el-input
                        ref="vocabularyCommentInputBoxEnCn"
                        class="mt-4"
                        style="30%"
                        v-model="vocabularyComment"
                        placeholder="评论区"
                        @keyup.enter="handleEnterOnCommentInputbox"
                        @click.stop
                        @keydown.stop
                        @keypress.stop
                        @keyup.stop
                      ></el-input>
                    </div>
                  </div>
                </div>

                <el-divider><i class="el-icon-mobile-phone"></i></el-divider>
                <div>
                  <!-- <div
                    class="image"
                    v-if="
                      vocList[vocCardItem.currentVocIndex].imagePath != null
                    "
                  >
                    <el-image
                      :src="
                        '../../../vocabulary/images/' +
                        vocList[vocCardItem.currentVocIndex].imagePath
                      "
                    ></el-image>
                  </div> -->
                  <div class="EnCnText CnText">
                    <p
                      @click.stop="handleTextClick()"
                      v-html="vocList[vocCardItem.currentVocIndex].chineseText"
                    ></p>
                  </div>
                </div>
              </div>
              <!-- </transition> -->
            </article>
            <div
              class="full-image"
              v-if="
                vocList[vocCardItem.currentVocIndex].imagePath != null &&
                vocList[vocCardItem.currentVocIndex].imagePath != '' &&
                showImage
              "
            >
              <!-- <el-image
            :src="
              '../../../vocabulary/images/' +
              vocList[vocCardItem.currentVocIndex].imagePath
            "
          ></el-image> -->
              <!-- <el-image
            src="
              'http://vocabulary.andover.com.cn/vocabulary/images/111486.jpeg' 
            "
          ></el-image> -->
              <!-- <img
                src="http://vocabulary.andover.com.cn/vocabulary/images/111486.jpeg"
                alt=""
              /> -->
              <img
                :src="
                  '../../../vocabulary/images/' +
                  vocList[vocCardItem.currentVocIndex].imagePath
                "
                alt=""
              />
            </div>
          </el-col>
          <el-col :span="2" class="controller-right">
            <div @click.stop="handleNextClick()">
              <el-icon><ArrowRightBold /></el-icon>
            </div>
          </el-col>
        </el-row>
        <!-- <div class="voc-player">
          <div class="controller-left" @click="handlePrevClick()">
            <el-icon><ArrowLeftBold /></el-icon>
          </div>
          <div class="controller-right" @click="handleNextClick()">
            <el-icon><ArrowRightBold /></el-icon>
          </div>
        </div> -->

        <footer class="footer-container">
          <div class="tags" v-for="(item, index) in localTags" :key="index">
            <el-check-tag
              :checked="item.tag"
              @change="onTagChange($event, item)"
              >{{ item.chineseName }}</el-check-tag
            >
          </div>
        </footer>

        <div class="progress-bar">
          <el-progress
            id="progressBar"
            :percentage="percentage"
            text-inside
            :show-text="false"
            @click="onProgressBarClicked($event)"
          ></el-progress>
        </div>
      </div>
    </div>

    <!-- <div class="progress-bar">
      <el-progress
        id="progressBar"
        :percentage="percentage"
        text-inside
        :show-text="false"
        @click="onProgressBarClicked($event)"
      ></el-progress>
      <span
        class="iconfont iconprevious_step clickPointer"
        @click="handlePrevClick()"
      ></span>
      <span></span>
      <span
        class="iconfont iconnextstep clickPointer"
        @click="handleNextClick()"
      ></span>
    </div> -->

    <create-test-form
      v-model="showCreateTestForm"
      :vocList="vocList"
      :studentId="targetId"
      :userId="userId"
      :origin="
        decodedQueryObject.projectName +
        '-' +
        decodedQueryObject.subjectName +
        '-' +
        decodedQueryObject.setName
      "
      @on-test-mode-form-cancel="showCreateTestForm = false"
      @on-test-mode-form-confirm="showCreateTestForm = false"
      @on-tool-config-form-close="showCreateTestForm = false"
    ></create-test-form>

    <voc-edit-form
      v-model="showVocEditForm"
      :vocItem="vocList[vocCardItem.currentVocIndex]"
      :currentVocIndex="vocCardItem.currentVocIndex"
      @on-voc-edit-form-close="showVocEditForm = false"
      @on-voc-edit-form-cancel="showVocEditForm = false"
      @on-voc-edit-form-confirm="handleVocEditFormConfirm($event)"
    ></voc-edit-form>

    <tool-config-form
      v-model="showToolConfigForm"
      @on-tool-config-form-cancel="showToolConfigForm = false"
      @on-tool-config-form-confirm="handleOnToolConfigFormConfirm($event)"
      @on-tool-config-form-close="handleToolConfigFormClose($event)"
      @on-audio-switch-change="handleAudioSwitchChange($event)"
      @on-starred-switch-change="handleStarredSwitchChange($event)"
      @on-answer-radio-group-change="handleAnswerRadioGroupChange($event)"
      :currentVocIndex="vocCardItem.currentVocIndex"
      :totalVocCount="vocCardItem.vocCount"
    ></tool-config-form>

    <customized-filter-form
      v-model="showCustomizedFilterForm"
      @on-customized-filter-form-cancel="handleOnCustomizedFilterFormClose"
      @on-customized-filter-form-confirm="
        handleOnCustomizedFilterFormConfirm($event)
      "
      @on-customized-filter-form-close="handleOnCustomizedFilterFormClose"
    ></customized-filter-form>

    <checking-overview-form
      ref="checkingOverviewForm"
      v-model="showCheckingOverviewForm"
      :decodedQueryObject="decodedQueryObject"
      :currentVocList="vocList"
      @on-checking-overview-form-cancel="showCheckingOverviewForm = false"
      @on-checking-overview-form-confirm="showCheckingOverviewForm = false"
    ></checking-overview-form>

    <date-filter-form
      v-model="showDateFilter"
      @on-date-filter-form-cancel="showDateFilter = false"
      @on-date-filter-form-confirm="handleDateFilterFormConfirm($event)"
      @on-date-filter-form-close="showDateFilter = false"
    ></date-filter-form>

    <!-- 编辑单词列表的抽屉 -->
    <el-drawer
      v-model="showVocListEditDrawer"
      @close="handleEditVocListDrawerClose"
      direction="ttb"
      size="100%"
    >
      <template #title>
        <h4>编辑整个单词列表</h4>
        <!-- 把当前列表的所有单词和Evian和Naive做mapping，看看哪些单词是来自于Evian和Naive -->
        <div>
          <el-button @click="handleMapIt" type="text" size="small"
            >Map It~</el-button
          >
        </div>
      </template>
      <template #default>
        <div>
          <el-table
            :data="vocList"
            style="width: 100%"
            class="vocListTable"
            @selection-change="handleSelectionChange"
          >
            <el-table-column type="selection" width="50" />
            <el-table-column type="index" width="50"></el-table-column>
            <el-table-column prop="englishText" label="单词" width="280">
              <template #default="scope">
                <span
                  @click="
                    handleEnglishTextColumnClick($event, scope.row.englishText)
                  "
                  >{{ scope.row.englishText }}</span
                >

                <span
                  v-if="scope.row.mappingWord"
                  :class="scope.row.mappingWord.marked ? 'red' : 'deepskyblue'"
                >
                  Match
                </span>
              </template>
            </el-table-column>

            <el-table-column prop="chineseText" label="释义">
              <template #default="scope">
                <span v-html="scope.row.chineseText" />
              </template>
            </el-table-column>
            <el-table-column prop="marked" label="标记" width="180">
              <template #default="scope">
                <el-row id="ListeningToolsBar" style="padding-top: 5px">
                  <el-col :span="3" id="MarkIcon">
                    <div>
                      <span
                        class="mark-heart"
                        :class="[
                          'iconfont',
                          'heart',
                          scope.row.marked ? 'icon-marked' : 'icon-unmarked'
                        ]"
                        @click="handleVocMarkClick(1, scope.$index)"
                      ></span>
                      <span
                        class="mark-heart"
                        :class="[
                          'iconfont',
                          'heart',
                          scope.row.marked && scope.row.count > 1
                            ? 'icon-marked'
                            : 'icon-unmarked'
                        ]"
                        @click="handleVocMarkClick(2, scope.$index)"
                      ></span>
                      <span
                        class="mark-heart"
                        :class="[
                          'iconfont',
                          'heart',
                          scope.row.marked && scope.row.count > 2
                            ? 'icon-marked'
                            : 'icon-unmarked'
                        ]"
                        @click="handleVocMarkClick(3, scope.$index)"
                      ></span>
                      <span
                        class="mark-heart"
                        :class="[
                          'iconfont',
                          'heart',
                          scope.row.marked && scope.row.count > 3
                            ? 'icon-marked'
                            : 'icon-unmarked'
                        ]"
                        @click="handleVocMarkClick(4, scope.$index)"
                      ></span>
                      <span
                        class="mark-heart"
                        :class="[
                          'iconfont',
                          'heart',
                          scope.row.marked && scope.row.count > 4
                            ? 'icon-marked'
                            : 'icon-unmarked'
                        ]"
                        @click="handleVocMarkClick(5, scope.$index)"
                      ></span>
                    </div>
                  </el-col>
                </el-row>
              </template>
            </el-table-column>
            <el-table-column
              label="操作"
              width="100"
              v-if="
                userRole == 'Admin' ||
                (projectType == 1 &&
                  (!decodedQueryObject.currentViewingTeacherId ||
                    decodedQueryObject.currentViewingTeacherId == userId))
              "
            >
              <template #default="scope">
                <el-button
                  @click="handleVocUpdateClick(scope.$index, scope.row)"
                  type="text"
                  size="small"
                  >编辑</el-button
                >
                <el-button
                  @click="handleVocDelete(scope.$index, scope.row.vocabularyId)"
                  type="text"
                  size="small"
                  >删除</el-button
                >
              </template>
            </el-table-column>
            <el-table-column
              prop="studentComent"
              label="学生评论"
            ></el-table-column>

            <el-table-column prop="ImagePath" label="图片" width="180">
              <template #default="scope">
                <el-image
                  style="width: 150px"
                  v-if="
                    scope.row.imagePath != null && scope.row.imagePath != ''
                  "
                  :src="'../../../vocabulary/images/' + scope.row.imagePath"
                ></el-image>
              </template>
            </el-table-column>
          </el-table>
          <!-- <el-button
            class="mt-4"
            style="width: 100%"
            @click="handleDuplicateEnglishText"
            >Duplicate Words - Beta</el-button
          > -->
          <div class="add-many-plugin" v-if="isAllowedAddMany">
            <voc-add-many-content />
          </div>
        </div>
      </template>
      <!-- <template #footer>
        <div style="flex: auto">
          <el-button @click="cancelClick">cancel</el-button>
          <el-button type="primary" @click="confirmClick">confirm</el-button>
        </div>
      </template> -->
    </el-drawer>
    <!-- 编辑单词列表的抽屉 -->

    <!-- 导出单词列表的抽屉 -->
    <voc-export-drawer
      v-model="showVocExportDrawer"
      :currentVocList="vocList"
      :currentMarkedVocs="currentMarkedVocs"
      @on-voc-export-drawer-closed="showVocExportDrawer = false"
      @on-handle-clear-current-marked-vocs="handleClearCurrentMarkedVocs"
    />
    <!-- 导出单词列表的抽屉 -->

    <!-- 点击其他subject之后出现sets列表 -->
    <el-dialog v-model="dialogSetListVisible">
      <el-input
        class="mt-4"
        style="100%"
        v-model="setNameSearchString"
        placeholder="此处输入集合名称来过滤集合"
      ></el-input>
      <el-checkbox
        v-model="checkAllSets"
        :indeterminate="isIndeterminate"
        @change="handleCheckAllChange"
        >Check all</el-checkbox
      >
      <div class="set-check-box-group">
        <el-checkbox-group
          v-model="checkedSets"
          @change="handleCheckedSetsChange"
        >
          <el-checkbox
            v-for="set in setList.filter(
              data =>
                !setNameSearchString ||
                data.name
                  .toLowerCase()
                  .includes(setNameSearchString.toLowerCase())
            )"
            :key="set.name"
            :label="set.id"
            >{{ set.name }}</el-checkbox
          >
        </el-checkbox-group>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogSetListVisible = false">Cancel</el-button>
          <el-button type="primary" @click="handleSetListDialogConfirm"
            >Confirm</el-button
          >
        </span>
      </template>
    </el-dialog>
    <!-- 点击其他subject之后出现sets列表 -->

    <!-- 显示帮助快捷键图片的dialog -->
    <el-dialog
      v-model="dialogHelpImageVisible"
      title="快捷键"
      width="90%"
      center
    >
      <div class="help-image-container">
        <img class="help-image" src="../../assets/shortcuts.jpeg" alt="help" />
      </div>
    </el-dialog>
    <!-- 显示帮助快捷键图片的dialog -->

    <div>
      <audio ref="vocaudio"></audio>
    </div>

    <div>
      <audio ref="listeningaudio"></audio>
    </div>

    <!-- 添加listening句子播放，以防listening down -->
    <div v-if="currentListeningArticleWithSentences">
      <span
        v-for="(item, index) in currentListeningArticleWithSentences.sentences"
        :key="index"
        style="margin-left: 1em"
        @click="
          handleListeningSentenceClick(
            item,
            currentListeningArticleWithSentences.title
          )
        "
      >
        {{ index + 1 }}
      </span>
    </div>

    <div v-if="listeningProgressBar.show" class="listening-progress-bar">
      <el-progress
        ref="progress"
        :percentage="listeningProgressBar.percentage"
        :show-text="false"
        @click="handleListeningProgressBarClick"
        style="cursor: pointer"
      ></el-progress>
    </div>

    <create-plan-detail-task-result-form
      v-model="showCreatePlanDetailTaskResultForm"
      :dataFromCard="dataFromCard"
      @on-plan-form-detail-task-result-cancel="
        showCreatePlanDetailTaskResultForm = false
      "
      @on-plan-form-detail-task-result-confirm="
        handlePlanFormDetailTaskResultConfirm($event)
      "
      @on-plan-form-daily-task-result-close="
        showCreatePlanDetailTaskResultForm = false
      "
    ></create-plan-detail-task-result-form>

    <div v-if="showVideoPlayer" class="video-player-overlay">
      <div class="video-player">
        <video ref="videoPlayer" controls>
          <source src="andover_voc_video.webm" type="video/webm" />
        </video>
        <button class="video_button" @click="showVideoPlayer = false">
          关闭
        </button>
      </div>
    </div>

    <el-dialog
      v-model="deepseekDialogVisible"
      title="来自VOC的神秘礼物"
      width="500"
    >
      <span>{{ deepseekResponse }}</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="deepseekDialogVisible = false">*.*</el-button>
          <el-button type="primary" @click="deepseekDialogVisible = false">
            ^_^
          </el-button>
        </div>
      </template>
    </el-dialog>

    <el-dialog v-model="williamDialogVisible" title="来自VOC的祝福" width="500">
      <span>William 生日快乐</span>
      <template #footer>
        <div class="dialog-footer">
          <el-button @click="williamDialogVisible = false">*.*</el-button>
          <el-button type="primary" @click="williamDialogVisible = false">
            ^_^
          </el-button>
        </div>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import VocEditForm from './VocEditForm.vue'
import ToolConfigForm from './ToolConfigForm.vue'
import { ElMessage, ElNotification } from 'element-plus'
import { ElLoading } from 'element-plus'
import Tags from './tags'
//import urls from "../../store/urls";
import VocAddManyContent from '../VocAddManyContent.vue'
import VocExportDrawer from './VocExportDrawer.vue'
import DateFilterForm from './DateFilterForm.vue'
import CheckingOverviewForm from './CheckingOverviewForm.vue'
import CreateTestForm from './CreateTestForm.vue'
import CustomizedFilterForm from './CustomizedFilterForm.vue'
import { h } from 'vue'
import CreatePlanDetailTaskResultForm from './plan/CreatePlanDetailTaskResultForm.vue'
import syllables from '../../plugins/syllablesSplitter.js'
import preProcessedWords from '../../plugins/preProcessedWords.json'

export default {
  name: 'Card',
  components: {
    VocEditForm,
    ToolConfigForm,
    VocAddManyContent,
    VocExportDrawer,
    DateFilterForm,
    CheckingOverviewForm,
    CreateTestForm,
    CustomizedFilterForm,
    CreatePlanDetailTaskResultForm
  },
  props: [],

  data () {
    return {
      williamDialogVisible: false,
      deepseekDialogVisible: false,
      deepseekResponse: '',
      deepseekIsLoading: false,
      showMeaning: false,
      showEnCn: false,
      showEn: true,
      percentage: 0,
      projectType: null,
      userRole: null,
      userId: null,
      targetId: null,
      targetName: null,
      isAllowedEditOrDeleteVoc: false,
      isAllowedAddMany: false,
      decodedQueryObject: null,
      selectedVocObjects: [],
      showVocExportDrawer: false,
      currentSubjects: null,
      dialogSetListVisible: false,
      dialogHelpImageVisible: false,
      setNameSearchString: '',
      checkAllSets: false,
      isIndeterminate: true,
      checkedSets: [],
      setList: [],

      vocList: [
        {
          englishText: '',
          chineseText: ''
        }
      ],

      vocCachedList: [],

      preVocListScreenShot: [],

      addOneEnglish: '',
      addOneChinese: '',

      vocCardItem: {
        vocItem: null,
        currentVocIndex: 0,
        vocCount: 0,
        currentVocTotalMarked: 0,
        currentVocLevel: null,
        setStarting: 0,
        setsTotalCount: 0,
        loaded: false
      },

      localTags: [],
      tagPOSChecked: false,
      tagEnglishMeaningChecked: false,
      tagMultiMeaningChecked: false,
      tagPhraseChecked: false,
      tagPronunciationChecked: false,
      tagExampleChecked: false,
      tagConfusionChecked: false,
      allowToAddHeartWithTag: true,

      showVocEditForm: false,
      showHelpMessageBox: false,
      showToolConfigForm: false,
      showVocListEditDrawer: false,
      showDateFilter: false,
      showCheckingOverviewForm: false,
      showCreateTestForm: false,
      showCustomizedFilterForm: false,

      dateFilterForm: {
        startDate: null,
        endDate: null
      },

      toolConfig: {
        random: false,
        audio: true,
        starred: false,
        answer: 1
      },

      forCellPhoneEvent: {
        startX: 0,
        startY: 0,
        moveX: 0,
        moveY: 0,
        endX: 0,
        endY: 0
      },

      showCommentInputbox: false,
      vocabularyComment: '',

      currentMarkedVocs: [],

      autoPlayInterval: null,
      activateAutoPlay: false,

      currentOverviewId: null,

      currentViewedVocIdList: [],

      isOverviewDetailsAdded: false,

      exampleSentencesPlayIndex: 0,

      currentListeningArticleWithSentences: null,

      showImage: false,

      manipulatingProcedure: [],
      showManipulatingProcedure: false,
      cacheVocListInProcedureSteps: [],

      listeningProgressBar: {
        percentage: 0,
        show: false
      },

      //plan - start
      isPlanDetailTask: false,
      showCreatePlanDetailTaskResultForm: false,
      dataFromCard: {
        taskInfo: null,
        resultInfo: null
      },
      checkingStartTime: null,
      //当前正在背诵的task
      currentTask: null,

      //plan - end

      //isPostingMark用于标记当前词汇是否处于正在跟服务器连接并标记状态，用于防止同时追加多条相同标记
      isPostingMark: false,

      //显示音节切分单词的开关
      showSyllables: false,

      //开关视频
      showVideoPlayer: false,

      //接收用于添加错词的diyprojects
      diyprojects: [],
      currentDIYProjectId: null,
      currentDIYSubjectId: null,
      currentDIYSetId: null
    }
  },

  watch: {
    showCreatePlanDetailTaskResultForm: {
      handler (newValue) {
        if (newValue) {
          document.removeEventListener('keydown', this.handleVocKeyDown) // remove global key down event before leaving voc page
        } else {
          document.addEventListener('keydown', this.handleVocKeyDown)
        }
      }
    }
  },

  beforeMount: function () {
    this.vocCardItem.vocItem = {
      EnglishText: '',
      ChineseText: '',
      ImagePath: ''
    }

    //this.navigationType = window.performance.navigation.type;

    // if (navigationType === 1) {
    //   // Page is refreshed
    //   console.log("Page is refreshed");
    // } else if (navigationType === 0) {
    //   // Page is redirected
    //   console.log("Page is redirected");
    // }
    // decode query string into object
    let queryString = window.atob(this.$route.query.encodedString)
    queryString = window.decodeURIComponent(queryString)
    this.decodedQueryObject = JSON.parse(queryString)
    //this.vocCardItem.vocItem = this.vocItem;
    //this.vocCardItem.currentVocIndex = this.currentVocIndex;
    //this.vocCardItem.vocCount = this.vocCount;
    //this.vocCardItem.currentVocMarkedCount = this.vocItem.MarkedCount;
    //console.log(this.decodedQueryObject);

    // test syllables
    //console.log(syllables('invisible'))
  },

  mounted: function () {
    //set background color
    document
      .querySelector('body')
      .setAttribute('style', 'background-color: rgb(246, 247, 251)')
    this.userRole = localStorage.getItem('userRole')
    this.userId = localStorage.getItem('userId')
    this.targetId = this.decodedQueryObject.userId
    this.targetName = this.decodedQueryObject.userName
    this.currentSubjects = this.decodedQueryObject.currentSubjects
    this.simplifySetNames()
    document.addEventListener('keydown', this.handleVocKeyDown)
    //这个keyup事件用来给addStudentOverview加debounce,防止学生不断按r随机之后，发送过多add请求
    document.addEventListener(
      'keyup',
      this.debounce(this.addStudentOverviewFinal, 1000)
    )
    this.initializeAddOrEditPermission()
    this.setPageTitle()
    this.initializeLocalTags()
    // this.vocCardItem.vocItem = this.vocItem;
    // this.vocCardItem.currentVocIndex = this.currentVocIndex;
    // this.vocCardItem.vocCount = this.vocCount;
    //document.addEventListener("keyup", this.handleVocCardKeyUp);
    this.vocCardItem.vocCount = this.vocList.length
    this.percentage =
      ((this.vocCardItem.currentVocIndex + 1) / this.vocCardItem.vocCount) * 100

    //this.getVocabularies();
    this.getVocabulariesBySingleSet()

    //获得audio的播放事件，以便更新进度条
    this.$refs.listeningaudio.addEventListener(
      'timeupdate',
      this.handleTimeUpdate
    )

    //如果url传过来的query对象有taskId，就说明是task
    this.isPlanDetailTask = this.decodedQueryObject.taskId ? true : false

    //获得当前的task，暂时主要用到的是isExaminingAsStudent
    if (this.isPlanDetailTask) {
      this.getCurrentTaskById()
    }
  },

  methods: {
    getVocabularies: function () {
      let params = {
        userId: this.targetId,
        setIds: this.decodedQueryObject.setIds
      }

      this.$store
        .dispatch('Vocabulary/getMarkedVocabularies', params)
        .then(response => {
          if (response.data.length >= 1) {
            this.vocList = response.data
            //拿到单词后，缓存，以便刷新的时候不用重新请求
            this.cacheVocList()
            this.initializeVocList()
            //新开一个card页面，拿到所有voc并初始化之后，post一条数据新建一个overview
            this.addStudentOverview()
            //拿到单词表后，handleMapit
            //mapit的性能太差，需要重构，目前先移除该自动功能，但是仍然可以通过点击页面的map it功能来实现
            //this.handleMapIt();
          } else {
            //如果vocList里面没有单词，就自动打开edit页面，为了添加单词
            this.handleShowVocListEditDrawer()
          }
        })
        .catch(error => console.log(error.toString()))
    },

    getCurrentTaskById: function () {
      this.$store
        .dispatch('StudentPlan/getStudentPlanDetailTaskById', {
          taskId: this.decodedQueryObject.taskId
        })
        .then(response => {
          this.currentTask = response.data
          console.log(this.currentTask)
        })
        .catch(error => this.$message(error.toString()))
    },

    handleListeningSentenceClick: function (sentence, title) {
      this.$refs.vocaudio.src =
        'https://vocabulary.andover.com.cn/vocabulary/audios/listeningAudios/' +
        title +
        '/' +
        sentence.audioPath +
        '.mp3'
      console.log(this.$refs.vocaudio.src)
      this.$refs.vocaudio.play()
    },

    getVocabulariesBySingleSet: function () {
      //把setIds数组变成n个数组
      //let singleSetIdArray = this.decodedQueryObject.setIds.map(x => [x]);

      //获得所有set数量，以及起始值设定为0；每次拿到一个set就+1，直到等于set数量
      this.vocCardItem.setsTotalCount = this.decodedQueryObject.setIds.length
      this.vocCardItem.setStarting = 0
      const loading = ElLoading.service({
        lock: true,
        text: '加载中，请稍后...',
        background: 'rgba(0, 0, 0, 0.7)'
      })

      this.vocList = []
      //这个chunk用来存每次拿回来的voc单个set词汇，用于最后的排序；否则拿到的lists就随机了
      let tempVocChunk = []
      for (let i = 0; i < this.decodedQueryObject.setIds.length; i++) {
        let params = {
          userId: this.targetId,
          setId: this.decodedQueryObject.setIds[i]
        }
        this.$store
          .dispatch('Vocabulary/getMarkedVocabulariesBySingleSet', params)
          .then(response => {
            //if (response.data) {
            if (response.data.length >= 1) {
              tempVocChunk.push({ index: i, tempList: response.data })
            }
            //this.vocList = this.vocList.concat(response.data);

            this.vocCardItem.setStarting += 1
            if (
              this.vocCardItem.setStarting == this.vocCardItem.setsTotalCount
            ) {
              //按加入的index升序排序
              tempVocChunk.sort((a, b) => a.index - b.index)
              this.vocList = tempVocChunk.reduce((combined, obj) => {
                return combined.concat(obj.tempList)
              }, [])
              this.initializeVocList()
              //设置loaded，一遍标记loaded状态
              this.vocCardItem.loaded = true
              //如果来自task，就按照setProcesses调整词汇集合
              if (this.isPlanDetailTask) {
                for (
                  let i = 0;
                  i < this.decodedQueryObject.setProcesses.length;
                  i++
                ) {
                  this.autoSetProcess(this.decodedQueryObject.setProcesses[i])
                }
              }
              this.cacheVocList()
              this.addStudentOverview()
              //this.handleMapIt();
              loading.close()
            }
            //拿到单词后，缓存，以便刷新的时候不用重新请求
            //无法确定什么时候可以缓存好voclist，所以先取消cache
            //this.cacheVocList()

            //新开一个card页面，拿到所有voc并初始化之后，post一条数据新建一个overview
            //this.addStudentOverview();
            //拿到单词表后，handleMapit
            //无法确定什么时候可以缓存好voclist，所以先取消map
            //this.handleMapIt();
            //} else {
            //如果vocList里面没有单词，就自动打开edit页面，为了添加单词
            //this.handleShowVocListEditDrawer();
            //}
          })
          .catch(error => console.log(error.toString()))
      }
    },

    appendVocabularies: function (setIds) {
      let params = {
        userId: this.targetId,
        setIds: setIds
      }

      this.$store
        .dispatch('Vocabulary/getMarkedVocabularies', params)
        .then(response => {
          if (response.data.length >= 1) {
            this.vocList = this.vocList.concat(response.data)
            //拿到单词后，缓存，以便刷新的时候不用重新请求
            this.cacheVocList()
            this.initializeVocList()
            //在card页面追加vocabularies的时候，追加完成并初始化之后，也post一条数据新建一个overview
            this.addStudentOverview()
          }
        })
        .catch(error => console.log(error.toString()))
    },

    simplifySetNames: function () {
      if (!this.decodedQueryObject.setName.includes('-')) return
      let setNamesArray = this.decodedQueryObject.setName.split('-')
      let preSetName = ''
      let postSetNameArray = []
      for (let i = 0; i < setNamesArray.length; i++) {
        let letters = setNamesArray[i].match(/[^0-9]+/g)
        let digits = setNamesArray[i].match(/\d+/g)
        if (letters) {
          preSetName = letters[0]
        } else {
          return
        }
        if (digits) {
          postSetNameArray.push(parseInt(digits))
        } else {
          return
        }
        //如果有多个字符串或者多个数字，说明不能按最后的数字合并，比如omg31c1
        if (letters && digits && (letters.length > 1 || digits.length > 1))
          return
      }
      this.decodedQueryObject.setName =
        preSetName + this.reduceRange(postSetNameArray).join(',')
    },

    //这个函数只为了simplifySetNames服务，用于把连续数字数组转成范围
    reduceRange: function (arr) {
      let ranges = []
      let start = arr[0]
      let end = arr[0]

      for (let i = 1; i < arr.length; i++) {
        if (arr[i] === end + 1) {
          end = arr[i]
        } else {
          ranges.push(`${start}-${end}`)
          start = arr[i]
          end = arr[i]
        }
      }

      ranges.push(`${start}-${end}`)

      return ranges
    },

    cacheVocList: function () {
      if (this.vocList) {
        this.vocCachedList = JSON.parse(JSON.stringify(this.vocList))
      }
    },

    //传递当前正在操作的词汇currentVoc，用vocabularyId找到cachedList里面的voc，然后更新cachedList
    updateVocInCachedList: function (currentVoc) {
      if (!this.vocCachedList || this.vocCachedList.length <= 0) return
      this.vocCachedList[
        this.vocCachedList.findIndex(
          item => item.vocabularyId == currentVoc.vocabularyId
        )
      ] = currentVoc
    },

    debounce: function (func, ms) {
      let timeout
      return function () {
        clearTimeout(timeout)
        // timeout = setTimeout(() => func.apply(this, arguments), ms);
        timeout = setTimeout(() => func.apply(this, arguments), ms)
      }
    },

    addStudentOverviewFinal: function (event) {
      if (event.keyCode == 82) this.addStudentOverview()
    },

    addStudentOverview: function () {
      let postData = {
        teacherId: this.userId,
        studentId: this.targetId,
        learningMode: this.userId == this.targetId ? '学生自学' : '老师检查',
        checkingMode: this.toolConfig.answer.toString(),
        vocProjectName: this.decodedQueryObject.projectName,
        vocSubjectName: this.decodedQueryObject.subjectName,
        vocSetNames: this.decodedQueryObject.setName,
        vocSetIds: this.decodedQueryObject.setIds.join(','),
        totalCount: this.vocCardItem.vocCount
      }

      this.$store
        .dispatch('StudentOverview/addVocStudentOverview', postData)
        .then(response => {
          if (response.data != -1) {
            this.currentOverviewId = response.data
            //添加新的overview，拿到overview id后，清空缓存的voc id list，一遍加入新的overview detail
            this.currentViewedVocIdList = []
            //用overviewId来insert overviewDetail; 需要准备overviewId，vocId，isCorrect
            this.upsertVocStudentOverviewDetail(true)
            //上面是初始化的时候加入第一个detail，所以可以认为还未加入，这里重制isOverviewDetailsAdded
            this.isOverviewDetailsAdded = false
          }
        })
        .catch(error => console.log(error.toString()))
    },

    upsertVocStudentOverviewDetail: function (isCorrect) {
      let postData = {
        vocStudentOverViewId: this.currentOverviewId,
        vocabularyId:
          this.vocList[this.vocCardItem.currentVocIndex].vocabularyId,
        isCorrect: isCorrect
      }

      this.$store
        .dispatch('StudentOverview/UpsertVocStudentOverviewDetail', postData)
        .then(response => {
          if (response.data) {
            //to be continued
            console.log('detail added')
          }
        })
        .catch(error => console.log(error.toString()))
    },

    initializeLocalTags: function () {
      this.localTags = Tags.TAGS
    },

    initializeAddOrEditPermission: function () {
      this.projectType = this.decodedQueryObject.projectType
      // setIds是数组，如果大于1，说明有多个学习集合并了
      if (this.decodedQueryObject.setIds.length == 1) {
        this.isAllowedAddMany = true
      } else {
        this.isAllowedAddMany = false
      }

      if (this.userRole == 'Admin') {
        this.isAllowedEditOrDeleteVoc = true
      } else if (this.userRole == 'Teacher') {
        if (this.projectType == 0) {
          this.isAllowedAddMany = false
          this.isAllowedEditOrDeleteVoc = false
        } else {
          this.isAllowedEditOrDeleteVoc = true
        }
      } else {
        //先把student权限都设定为false
        this.isAllowedAddMany = false
        this.isAllowedEditOrDeleteVoc = false
        //然后判断是否打开的是DIY学生自己的学习集，如果是，则允许编辑
        if (
          this.projectType == 1 &&
          (!this.decodedQueryObject.currentViewingTeacherId ||
            this.decodedQueryObject.currentViewingTeacherId == this.userId)
        ) {
          this.isAllowedEditOrDeleteVoc = true
          // 补一个setIds是一个或多个的情况
          if (this.decodedQueryObject.setIds.length == 1) {
            this.isAllowedAddMany = true
          } else {
            this.isAllowedAddMany = false
          }
        }
      }

      // if (
      //   this.decodedQueryObject.setName &&
      //   this.decodedQueryObject.setName.length > 0
      // ) {
      //   if (
      //     (this.userRole == "Admin" || this.projectType == 1) &&
      //     this.userId == this.targetId
      //   )
      //     this.isAllowedAddMany = true;
      //   else this.isAllowedAddMany = false;
      // } else this.isAllowedAddMany = false;

      // if (
      //   this.decodedQueryObject.currentViewingTeacherId &&
      //   this.decodedQueryObject.currentViewingTeacherId != this.userId
      // ) {
      //   this.isAllowedAddMany = false;
      // }
    },

    setPageTitle: function () {
      if (this.decodedQueryObject.projectName) {
        document.title =
          this.targetName ||
          localStorage.getItem('userCNName') +
            ' - ' +
            'Andover - ' +
            this.decodedQueryObject.projectName
      } else {
        if (this.decodedQueryObject.setName) {
          document.title =
            this.targetName ||
            localStorage.getItem('userCNName') +
              ' - ' +
              'Andover - ' +
              this.decodedQueryObject.setName
        }
      }
    },

    //handle click subject navigating to different subject
    handleSubjectRowClick: function (row) {
      this.dialogSetListVisible = true
      this.clearCheckedSets()
      this.getSetsBySubjectId(row.id)
    },

    getSetsBySubjectId: function (subjectId) {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('Set/getSetsBySubjectId', {
            subjectId: subjectId
          })
          .then(response => {
            this.setList = response.data
            resolve(response)
          })
          .catch(error => reject(error))
      })
    },

    handleCheckAllChange: function (event) {
      let tempCheckedSets = []
      let tempCheckedAllSetList = this.setList.filter(
        data =>
          !this.setNameSearchString ||
          data.name
            .toLowerCase()
            .includes(this.setNameSearchString.toLowerCase())
      )
      if (event) {
        for (let i = 0; i < tempCheckedAllSetList.length; i++) {
          tempCheckedSets.push(tempCheckedAllSetList[i].id)
        }
      }

      this.checkedSets = event ? tempCheckedSets : []
      this.isIndeterminate = false
    },

    handleCheckedSetsChange: function (event) {
      const checkedCount = event.length
      this.checkAllSets = checkedCount === this.setList.length
      this.isIndeterminate =
        checkedCount > 0 && checkedCount < this.setList.length
    },

    clearCheckedSets: function () {
      this.checkAllSets = false
      this.isIndeterminate = true
      this.checkedSets = []
    },

    handleSetListDialogConfirm: function () {
      this.dialogSetListVisible = false
      //找到当前projectid 下的所有subjects，作为query参数传到card页面作为导航不同subject的功能
      this.appendVocabularies(this.checkedSets)
      this.addOriginSetName()
    },

    addOriginSetName: function () {
      for (let i = 0; i < this.checkedSets.length; i++) {
        let addedSetName = this.setList.find(
          item => item.id == this.checkedSets[i]
        ).name
        this.decodedQueryObject.setName += '-' + addedSetName
      }
    },

    //handle click subject navigating to different subject

    handleShowVocListEditDrawer: function () {
      this.showVocListEditDrawer = true
      document.removeEventListener('keydown', this.handleVocKeyDown) // remove global key down event before leaving voc page
      this.$store.commit('Set/mutateAddedSet', {
        setId: this.decodedQueryObject.setIds[0],
        setName: this.decodedQueryObject.setName
      })
    },

    handleEditVocListDrawerClose: function () {
      //this.$router.go(0);
      document.addEventListener('keydown', this.handleVocKeyDown)
    },

    handleVocMarkClick: function (markOrder, index) {
      if (index) {
        this.vocCardItem.currentVocIndex = index
      }
      //在add标记之前添加overview detail，因为有时候标记会失败，但是overview却可以update数据，只是没有具体标记的词
      //标记单词时，在overview的detail里面去更新这个detail; isCorrect 是false，因为标记的词表明学生回答错误
      this.upsertVocStudentOverviewDetail(false)
      //this.$emit("voc-mark-click");
      if (
        this.vocList[this.vocCardItem.currentVocIndex].marked == false ||
        (this.vocList[this.vocCardItem.currentVocIndex].marked == true &&
          (markOrder > this.vocList[this.vocCardItem.currentVocIndex].count ||
            this.vocList[this.vocCardItem.currentVocIndex].count == 5))
      ) {
        this.addOrCancelMark(true)
      } else {
        this.addOrCancelMark(false)
      }
    },

    handleStudentMarkClick: function () {
      // if (index) {
      //   this.vocCardItem.currentVocIndex = index;
      // }
      this.vocList[this.vocCardItem.currentVocIndex].studentMarked =
        !this.vocList[this.vocCardItem.currentVocIndex].studentMarked
      //修改本地单词的状态后，更新vocCachedList的状态
      this.updateVocInCachedList(this.vocList[this.vocCardItem.currentVocIndex])
      this.toggleStudentMark(
        this.vocList[this.vocCardItem.currentVocIndex].studentMarked
      )
    },

    toggleStudentMark: function (studentMarked) {
      this.$store
        .dispatch('Vocabulary/studentMarkVocabulary', {
          userId: this.targetId,
          vocabularyId:
            this.vocList[this.vocCardItem.currentVocIndex].vocabularyId,
          marked: studentMarked
        })
        .then(() => {})
        .catch(error => console.log(error.toString()))
    },

    addOrCancelMark: function (add) {
      // 如果是学生想要在Andover的词汇上取消标记，则不执行
      if (this.userRole == 'Student' && this.projectType == 0 && add == false)
        return

      //如果isPostingMark是true，说明之前标记还没顺利response，则不允许继续标记
      if (this.isPostingMark) {
        ElMessage({
          type: 'warning',
          message: '之前的标记尚未提交，请检查网络～'
        })
        return
      }

      //标记或者取消标记的时候，实时更新这个词的updatedDate，以便之后过滤
      this.vocList[this.vocCardItem.currentVocIndex].markUpdatedDate = new Date()

      //标记单词时，将当前标记单词加入currentMarkedVocs中，以便快速整理出这次抽查的标记总数
      if (add) {
        let markedVocIndex = this.currentMarkedVocs.findIndex(
          item =>
            item.vocabularyId ==
            this.vocList[this.vocCardItem.currentVocIndex].vocabularyId
        )
        if (markedVocIndex < 0) {
          this.currentMarkedVocs.push(
            this.vocList[this.vocCardItem.currentVocIndex]
          )
        }
      } else {
        //减去标记时，移除current marked vocs里面的单词
        this.currentMarkedVocs.splice(
          this.currentMarkedVocs.findIndex(
            item =>
              item.vocabularyId ==
              this.vocList[this.vocCardItem.currentVocIndex].vocabularyId
          ),
          1
        )
      }

      //每次点击标记的时候，自动生成一个拷贝副本以便直接复制当次检查的错词，选好格式；调用minusMark的时候也需要调用一次这个函数
      this.copyIncorrectsToClip()

      //限定允许星标的最大数量，如果超过最大值，则点问号不再新增count
      if (
        (this.vocList[this.vocCardItem.currentVocIndex].count >= 0 &&
          this.vocList[this.vocCardItem.currentVocIndex].count < 6) ||
        (this.vocList[this.vocCardItem.currentVocIndex].count >= 6 &&
          add == false)
      ) {
        //储存标记的时候，当前的index，以便之后还原标记可能使用
        let vocIndexBeforeMarkCache = this.vocCardItem.currentVocIndex
        //储存标记的时候，当前的词汇标记数量和状态，以便之后还原标记可能使用
        let vocBeforeMarkCountCache =
          this.vocList[vocIndexBeforeMarkCache].count
        let vocBeforeMarkStatusCache =
          this.vocList[vocIndexBeforeMarkCache].marked

        // 本地先加上标记，再调用标记接口
        this.vocList[this.vocCardItem.currentVocIndex].marked = add
        //修改本地单词的状态后，更新vocCachedList的状态
        this.updateVocInCachedList(
          this.vocList[this.vocCardItem.currentVocIndex]
        )
        if (add) {
          this.vocList[this.vocCardItem.currentVocIndex].count += 1
          this.$refs.checkingOverviewForm.appendVocEnglishToCache(
            this.vocList[this.vocCardItem.currentVocIndex].englishText
          )
        } else {
          this.vocList[this.vocCardItem.currentVocIndex].count = 0
          this.$refs.checkingOverviewForm.removeVocEnglishToCache(
            this.vocList[this.vocCardItem.currentVocIndex].englishText
          )
        }

        this.isPostingMark = true
        this.$store
          .dispatch('Vocabulary/markVocabulary', {
            userId: this.targetId,
            vocabularyId:
              this.vocList[this.vocCardItem.currentVocIndex].vocabularyId,
            count: this.vocList[this.vocCardItem.currentVocIndex].count,
            marked: add,
            updatedBy: this.userId
          })
          .then(response => {
            //服务器标记和本地标记之间的延迟，会导致如果网速慢，服务器传回then的速度慢，如果已经进入下一个词，则会标记错，或者多出来一个词；
            //所以把标记的动作放在then之前，也就是本地先标记，然后再传服务器，如果没标记成功，则给出提示
            // this.vocList[this.vocCardItem.currentVocIndex].marked = add;
            // if (add) {
            //   this.vocList[this.vocCardItem.currentVocIndex].count += 1;
            //   this.$refs.checkingOverviewForm.appendVocEnglishToCache(
            //     this.vocList[this.vocCardItem.currentVocIndex].englishText
            //   );
            // } else {
            //   this.vocList[this.vocCardItem.currentVocIndex].count = 0;
            //   console.log(this.vocList[this.vocCardItem.currentVocIndex].englishText)
            //   this.$refs.checkingOverviewForm.removeVocEnglishToCache(
            //     this.vocList[this.vocCardItem.currentVocIndex].englishText
            //   );
            // }
            //得到response得时候，不管是否正常，都判定为结束标记
            this.isPostingMark = false
            if (response.data) {
              ElMessage({
                type: 'success',
                message: `${vocIndexBeforeMarkCache + 1}-${
                  this.vocList[vocIndexBeforeMarkCache].englishText
                }-已标记～`
              })
            } else {
              //没标记成功
              ElMessage({
                type: 'error',
                message: `${vocIndexBeforeMarkCache + 1}-${
                  this.vocList[vocIndexBeforeMarkCache].englishText
                }-未能成功标记，请检查网络 RESPONSE ERROR`
              })
              //回调标记词汇之前的状态
              this.vocList[vocIndexBeforeMarkCache].count =
                vocBeforeMarkCountCache
              this.vocList[vocIndexBeforeMarkCache].marked =
                vocBeforeMarkStatusCache
            }
          })
          .catch(() => {
            ElMessage({
              type: 'error',
              message: `${vocIndexBeforeMarkCache + 1}-${
                this.vocList[vocIndexBeforeMarkCache].englishText
              }未能成功标记，请检查网络 Catch ERROR`
            })
            //回调标记词汇之前的状态
            this.vocList[vocIndexBeforeMarkCache].count =
              vocBeforeMarkCountCache
            this.vocList[vocIndexBeforeMarkCache].marked =
              vocBeforeMarkStatusCache
          })
        //得到response得时候，不管是否正常，都判定为结束标记
        this.isPostingMark = false
      }
    },

    minusMark: function () {
      //减去标记时，移除current marked vocs里面的单词
      this.currentMarkedVocs.splice(
        this.currentMarkedVocs.findIndex(
          item =>
            item.vocabularyId ==
            this.vocList[this.vocCardItem.currentVocIndex].vocabularyId
        ),
        1
      )
      //与addOrCancelMark函数相同的调用一次copy到剪切板的副本
      this.copyIncorrectsToClip()
      // 如果是学生想要在Andover的词汇上取消标记，则不执行
      if (this.userRole == 'Student' && this.projectType == 0) return
      if (
        !this.vocList[this.vocCardItem.currentVocIndex].marked ||
        this.vocList[this.vocCardItem.currentVocIndex].count <= 0
      )
        return
      if (this.vocList[this.vocCardItem.currentVocIndex].count > 1) {
        this.$store
          .dispatch('Vocabulary/markVocabulary', {
            userId: this.targetId,
            vocabularyId:
              this.vocList[this.vocCardItem.currentVocIndex].vocabularyId,
            count: this.vocList[this.vocCardItem.currentVocIndex].count - 1,
            marked: true,
            updatedBy: this.userId
          })
          .then(() => {
            this.vocList[this.vocCardItem.currentVocIndex].count -= 1
            //修改本地单词的状态后，更新vocCachedList的状态
            this.updateVocInCachedList(
              this.vocList[this.vocCardItem.currentVocIndex]
            )
          })
          .catch(error => console.log(error.toString()))
      } else {
        this.$store
          .dispatch('Vocabulary/markVocabulary', {
            userId: this.targetId,
            vocabularyId:
              this.vocList[this.vocCardItem.currentVocIndex].vocabularyId,
            count: this.vocList[this.vocCardItem.currentVocIndex].count - 1,
            marked: false,
            updatedBy: this.userId
          })
          .then(() => {
            this.vocList[this.vocCardItem.currentVocIndex].marked = false
            this.vocList[this.vocCardItem.currentVocIndex].count -= 1
            //修改本地单词的状态后，更新vocCachedList的状态
            this.updateVocInCachedList(
              this.vocList[this.vocCardItem.currentVocIndex]
            )
          })
          .catch(error => console.log(error.toString()))
      }
    },

    //每次标记或取消标记后，自动复制当前的错词到剪切板
    copyIncorrectsToClip: function () {
      //把current marked voc里面的所有单词的englishText都以一定的格式复制到剪切板以便直接复制
      let result = ''
      //获得targetCNName
      result += localStorage.getItem('targetCNName') + ': '
      //获得当前科目名字
      result += this.decodedQueryObject.subjectName + ': '
      //获得当前学习集名字
      result +=
        this.decodedQueryObject.setName +
        //获取当前list的操作步骤
        '[' +
        (this.manipulatingProcedure.length > 0
          ? this.manipulatingProcedure.join('->')
          : '') +
        ']: '
      //获得当前错词数量
      result +=
        '(-' +
        this.currentMarkedVocs.length +
        '/' +
        (this.vocCardItem.currentVocIndex + 1) +
        '/' +
        this.vocList.length +
        ') : '
      //拼接当前错词EnglishText，map每个voc到englishText，然后join
      result += this.currentMarkedVocs.map(item => item.englishText).join(', ')
      let copyIncorrectsInput = document.createElement('input')
      copyIncorrectsInput.setAttribute('value', result)
      document.body.appendChild(copyIncorrectsInput)
      copyIncorrectsInput.select()
      try {
        let copied = document.execCommand('copy')
        if (copied) {
          document.body.removeChild(copyIncorrectsInput)
        }
      } catch {
        document.body.removeChild(copyIncorrectsInput)
      }
      return result
    },

    markAsBlack: function () {
      //如果isPostingMark是true，说明之前标记还没顺利response，则不允许继续标记
      if (this.isPostingMark) {
        ElMessage({
          type: 'warning',
          message: '之前的标记尚未提交，请检查网络～'
        })
        return
      }

      let markedVocIndex = this.currentMarkedVocs.findIndex(
        item =>
          item.vocabularyId ==
          this.vocList[this.vocCardItem.currentVocIndex].vocabularyId
      )
      if (markedVocIndex < 0) {
        this.currentMarkedVocs.push(
          this.vocList[this.vocCardItem.currentVocIndex]
        )
      }

      //每次点击标记的时候，自动生成一个拷贝副本以便直接复制当次检查的错词，选好格式；调用minusMark的时候也需要调用一次这个函数
      this.copyIncorrectsToClip()

      //储存标记的时候，当前的index，以便之后还原标记可能使用
      let vocIndexBeforeMarkCache = this.vocCardItem.currentVocIndex
      //储存标记的时候，当前的词汇标记数量和状态，以便之后还原标记可能使用
      let vocBeforeMarkCountCache = this.vocList[vocIndexBeforeMarkCache].count
      let vocBeforeMarkStatusCache =
        this.vocList[vocIndexBeforeMarkCache].marked

      // 本地先加上标记，再调用标记接口
      this.vocList[this.vocCardItem.currentVocIndex].marked = true
      //修改本地单词的状态后，更新vocCachedList的状态
      this.updateVocInCachedList(this.vocList[this.vocCardItem.currentVocIndex])

      //6个就是直接黑色
      this.vocList[this.vocCardItem.currentVocIndex].count = 6
      this.$refs.checkingOverviewForm.appendVocEnglishToCache(
        this.vocList[this.vocCardItem.currentVocIndex].englishText
      )

      this.isPostingMark = true
      this.$store
        .dispatch('Vocabulary/markVocabulary', {
          userId: this.targetId,
          vocabularyId:
            this.vocList[this.vocCardItem.currentVocIndex].vocabularyId,
          count: this.vocList[this.vocCardItem.currentVocIndex].count,
          marked: true,
          updatedBy: this.userId
        })
        .then(response => {
          //服务器标记和本地标记之间的延迟，会导致如果网速慢，服务器传回then的速度慢，如果已经进入下一个词，则会标记错，或者多出来一个词；
          //所以把标记的动作放在then之前，也就是本地先标记，然后再传服务器，如果没标记成功，则给出提示
          // this.vocList[this.vocCardItem.currentVocIndex].marked = add;
          // if (add) {
          //   this.vocList[this.vocCardItem.currentVocIndex].count += 1;
          //   this.$refs.checkingOverviewForm.appendVocEnglishToCache(
          //     this.vocList[this.vocCardItem.currentVocIndex].englishText
          //   );
          // } else {
          //   this.vocList[this.vocCardItem.currentVocIndex].count = 0;
          //   console.log(this.vocList[this.vocCardItem.currentVocIndex].englishText)
          //   this.$refs.checkingOverviewForm.removeVocEnglishToCache(
          //     this.vocList[this.vocCardItem.currentVocIndex].englishText
          //   );
          // }
          //得到response得时候，不管是否正常，都判定为结束标记
          this.isPostingMark = false
          if (response.data) {
            ElMessage({
              type: 'success',
              message: `${vocIndexBeforeMarkCache + 1}-${
                this.vocList[vocIndexBeforeMarkCache].englishText
              }-已标记为黑色～`
            })
          } else {
            //没标记成功
            ElMessage({
              type: 'error',
              message: `${vocIndexBeforeMarkCache + 1}-${
                this.vocList[vocIndexBeforeMarkCache].englishText
              }-未能成功标记，请检查网络 RESPONSE ERROR`
            })
            //回调标记词汇之前的状态
            this.vocList[vocIndexBeforeMarkCache].count =
              vocBeforeMarkCountCache
            this.vocList[vocIndexBeforeMarkCache].marked =
              vocBeforeMarkStatusCache
          }
        })
        .catch(() => {
          ElMessage({
            type: 'error',
            message: `${vocIndexBeforeMarkCache + 1}-${
              this.vocList[vocIndexBeforeMarkCache].englishText
            }未能成功标记，请检查网络 Catch ERROR`
          })
          //回调标记词汇之前的状态
          this.vocList[vocIndexBeforeMarkCache].count = vocBeforeMarkCountCache
          this.vocList[vocIndexBeforeMarkCache].marked =
            vocBeforeMarkStatusCache
        })
      //得到response得时候，不管是否正常，都判定为结束标记
      this.isPostingMark = false
    },

    //每次标记或取消标记后，自动复制当前的错词到剪切板
    generateTaskResults: function () {
      //把current marked voc里面的所有单词的englishText都以一定的格式复制到剪切板以便直接复制
      let result = ''
      //获得当前错词数量
      result +=
        '-' +
        this.currentMarkedVocs.length +
        '/' +
        (this.vocCardItem.currentVocIndex + 1) +
        '/' +
        this.vocList.length +
        ': '
      //拼接当前错词EnglishText，map每个voc到englishText，然后join
      result += this.currentMarkedVocs.map(item => item.englishText).join(', ')
      return result
    },

    handleTextClick: function (enText) {
      this.playCurrentVoc()
      this.showCommentInputbox = true
      if (enText) {
        this.copyEnText(enText)
      }

      this.$nextTick(() => {
        this.$refs.vocabularyCommentInputBoxEn.focus()
      })
    },

    //实验点击复制功能
    copyEnText: function (enText) {
      let str = enText
      let copyInput = document.createElement('input')
      copyInput.setAttribute('value', str)
      document.body.appendChild(copyInput)
      copyInput.select()
      try {
        let copied = document.execCommand('copy')
        if (copied) {
          document.body.removeChild(copyInput)
          ElMessage({
            type: 'success',
            message: '复制单词：' + str
          })
        }
      } catch {
        document.body.removeChild(copyInput)
        ElMessage('复制失败')
      }
    },

    handleVocClick: function () {
      this.showMeaning = !this.showMeaning
      this.showCommentInputbox = false
      if (this.toolConfig.audio && !this.showMeaning) {
        this.playCurrentVoc()
      }
      this.$emit('voc-click-in-card', this.showMeaning)
    },

    handleAudioIconClick: function () {
      //this.playCurrentVoc();
      //音频播放按钮不在播放声音，而是切换禁音状态
      this.toolConfig.audio = !this.toolConfig.audio
      //当切换到播放音频的时候，播放当前词汇(添加本地音频播放，暂时注释这一条)
      //if (this.toolConfig.audio) this.playCurrentVoc();

      //切换的时候，用本地音频的链接播放
      this.playVocByYoudao()
    },

    playCurrentVoc: function () {
      var currentVoc = this.vocList[
        this.vocCardItem.currentVocIndex
      ].englishText
        .trim()
        .toLowerCase()

      this.$playAudio(currentVoc, this.$refs.vocaudio)
      // hole 发音不对，替换成whole
      // if (currentVoc == "hole") currentVoc = "whole";
      // var vocAPI = urls.YoudaoPronunciationUrl;
      // this.$refs.vocaudio.src = vocAPI + currentVoc;
      // this.$refs.vocaudio.play();
    },

    playVocByYoudao: function () {
      var currentVoc = this.vocList[
        this.vocCardItem.currentVocIndex
      ].englishText
        .trim()
        .toLowerCase()

      this.$refs.vocaudio.src =
        'http://dict.youdao.com/dictvoice?type=1&audio=' + currentVoc
      this.$refs.vocaudio.play()
    },

    playCurrentExampleSentence: function () {
      let currentExampleSentences = this.retrieveCurrentExampleSentences()
      if (
        currentExampleSentences.length > 0 &&
        currentExampleSentences[this.exampleSentencesPlayIndex]
      ) {
        this.$playYoudaoAudio(
          currentExampleSentences[this.exampleSentencesPlayIndex],
          this.$refs.vocaudio
        )
        this.exampleSentencesPlayIndex += 1
        //播放到最后一句话的时候，重置index到0，循环播放
        if (this.exampleSentencesPlayIndex == currentExampleSentences.length) {
          this.exampleSentencesPlayIndex = 0
        }
      }
    },

    retrieveCurrentExampleSentences: function () {
      let text = this.vocList[this.vocCardItem.currentVocIndex].chineseText
      let substr = '例句：'
      let indices = []
      for (const match of text.matchAll(new RegExp(substr, 'g'))) {
        indices.push(match.index + '例句：'.length)
      }
      let result = []
      for (let i = 0; i < indices.length; i++) {
        const start = indices[i]
        const end = text.indexOf('<', start)
        result.push(text.substring(start, end))
      }
      // const start = text.indexOf("例句：") + "例句：".length;
      // const end = text.indexOf("<", start);
      // const result = text.substring(start, end);
      return result
    },

    getAudioPathFromChinese: function () {
      let text = this.vocList[this.vocCardItem.currentVocIndex].chineseText
      if (!text.includes("<div style='color: white'>")) return ''
      const start =
        text.indexOf("<div style='color: white'>") +
        "<div style='color: white'>".length
      const end = text.indexOf('<', start)
      const result = text.substring(start, end)
      return result
    },

    //合并P L K 3个快捷键，都变成L
    playSentenceIfAny: function () {
      if (
        this.decodedQueryObject.setIds.length == 1 &&
        this.decodedQueryObject.subjectName == 'Panoramic听力词汇'
      ) {
        this.getListeningArticleByTitle()
      } else {
        let audioPath = this.getAudioPathFromChinese()
        if (audioPath) {
          this.playListeningSentence(0)
        } else {
          this.playCurrentExampleSentence()
        }
      }
      this.playListeningSentence
    },

    playListeningSentence: function (percentage) {
      let audioPath = this.getAudioPathFromChinese()
      let currentListeningAudio = this.$refs.listeningaudio
      let currentListeningAudioDuration = currentListeningAudio.duration
      if (audioPath.length <= 0) {
        this.listeningProgressBar.show = false
        return
      }
      this.listeningProgressBar.show = true
      let fullAudioPath =
        'https://vocabulary.andover.com.cn/vocabulary/audios/listeningAudios/' +
        audioPath
      currentListeningAudio.src = fullAudioPath
      currentListeningAudio.currentTime =
        (percentage / 100) * currentListeningAudioDuration
      currentListeningAudio.play()
    },

    handleTimeUpdate: function () {
      let listeningAudio = this.$refs.listeningaudio
      let currentPlayingTime = listeningAudio.currentTime
      let percentage = (currentPlayingTime / listeningAudio.duration) * 100
      this.listeningProgressBar.percentage = parseInt(percentage)
    },

    handleListeningProgressBarClick (event) {
      console.log(event)
      console.log(this.$refs.progress.$el.clientWidth)
      console.log(this.$refs.progress.$el.getBoundingClientRect().left)
      // Get the width of the progress bar
      const progressBarWidth = this.$refs.progress.$el.clientWidth

      // Get the X position of the click event relative to the progress bar
      const clickX =
        event.clientX - this.$refs.progress.$el.getBoundingClientRect().left

      // Calculate the percentage based on the click position
      const newPercentage = parseInt((clickX / progressBarWidth) * 100)

      // Update the percentage in your data
      this.listeningProgressBar.percentage = newPercentage
      this.playListeningSentence(newPercentage)
    },

    beforeVocChanged: function () {
      if (this.$refs.vocaudio != null) {
        this.$refs.vocaudio.pause()
        this.$refs.vocaudio.currentTime = 0
      }
    },

    vocChanged: function (index) {
      this.showMeaning = false
      // voc 切换的时候，暂时隐藏释义，以免看到下个单词的释义
      // this.vocCardItem.hideCnTemporary = true;
      // setTimeout(() => {
      //   this.vocCardItem.hideCnTemporary = false;
      // }, 500);

      //如果单词有多个同音发音，则给出提示-start
      if (this.vocList[index].hint) this.openVocHintNotice(index)
      //如果单词有多个同音发音，则给出提示-end

      //如果单词有多个意思，则给出提示-start
      this.openMultiMeaningsNotice(index)
      //如果单词有多个意思，则给出提示-end

      this.allowToAddHeartWithTag = true
      if (this.toolConfig.answer == 2) {
        this.showMeaning = true
      }

      this.vocCardItem.currentVocIndex = index

      //如果当前的index大于0，就自动更新copy到剪切板;效果等同于点击“小抄”按钮
      if (index != 0) {
        this.copyIncorrectsToClip()
      }

      this.percentage =
        ((this.vocCardItem.currentVocIndex + 1) / this.vocCardItem.vocCount) *
        100
      if (this.toolConfig.audio) {
        this.playCurrentVoc()
      }
      this.initializeTags()
      this.vocCardItem.currentVocLevel = this.getCurrentVocLevel()
      //this.percentage = ((this.vocCardItem.currentVocIndex + 1) / this.vocCardItem.vocCount) * 100
      //匹配spelling，看是否正确
      this.compareSpelling()

      //给每个单词加上切分音节的属性，syllables
      this.splitSyllables()

      // reset vocabulary comment - start
      this.vocabularyComment = ''
      this.showCommentInputbox = false
      // reset vocabulary comment - end

      //每次改变voc的时候，重置例句的下标到0
      this.exampleSentencesPlayIndex = 0

      //获取path，如果有内容，显示进度条
      this.listeningProgressBar.show =
        this.getAudioPathFromChinese().length > 0 ? true : false
    },

    openVocHintNotice: function (index) {
      ElNotification.success({
        title: '提示',
        message: this.vocList[index].hint,
        offset: 200
      })
    },

    openMultiMeaningsNotice: function (index) {
      let multiMarker = ['②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨']
      let lastMultiMeaningNumber = ''
      let lastLoopIndex = 0
      for (let i = 0; i < multiMarker.length; i++) {
        if (this.vocList[index].chineseText.includes(multiMarker[i])) {
          lastMultiMeaningNumber = multiMarker[i]
          lastLoopIndex = i
        }
      }
      if (lastMultiMeaningNumber) {
        ElNotification.success({
          title: '注意多义词',
          message: h(
            'span',
            { style: 'color: deepskyblue' },
            `有${lastMultiMeaningNumber}个意思哦！`
          ),
          offset: 200 + 200 * lastLoopIndex
        })
      }
    },

    getCurrentVocLevel: function () {
      let currentChineseText =
        this.vocList[this.vocCardItem.currentVocIndex].chineseText
      if (currentChineseText.includes('A1')) return 'A1'
      else if (currentChineseText.includes('A2')) return 'A2'
      else if (currentChineseText.includes('B1')) return 'B1'
      else if (currentChineseText.includes('B2')) return 'B2'
      else if (currentChineseText.includes('C1')) return 'C1'
      else if (currentChineseText.includes('C2')) return 'C2'
      else return null
    },

    initializeTags: function () {
      this.resetAllTags()
      for (
        let i = 0;
        i <
        this.vocList[this.vocCardItem.currentVocIndex].vocabularyTags.length;
        i++
      ) {
        this.localTags[
          this.localTags.findIndex(item => {
            return (
              item.id ==
              this.vocList[this.vocCardItem.currentVocIndex].vocabularyTags[i]
                .id
            )
          })
        ].tag = true
      }
    },

    resetAllTags: function () {
      for (let i = 0; i < this.localTags.length; i++) {
        this.localTags[i].tag = false
      }
    },

    handlePrevClick: function () {
      if (this.vocCardItem.currentVocIndex > 0)
        this.vocChanged(this.vocCardItem.currentVocIndex - 1)
    },

    handleNextClick: function () {
      if (this.vocCardItem.currentVocIndex == this.vocList.length - 1) {
        this.vocChanged(0)
        //如果从最后一个跳回第一个单词，则增加一个新的overview
        this.addStudentOverview()
        ////如果从最后一个跳回第一个单词，则清空当前标记的词汇的本地存储
        this.handleClearCurrentMarkedVocs()
        return
      }
      if (this.vocCardItem.currentVocIndex < this.vocList.length - 1) {
        this.vocChanged(this.vocCardItem.currentVocIndex + 1)
        //如果点next的时候，index是1，也就是第二个单词，就记录一下时间，当作开始检查的时间
        if (this.isPlanDetailTask && this.vocCardItem.currentVocIndex == 1) {
          this.checkingStartTime = new Date()
        }
        //把当前的voc id记录下来，连成数组，作为当前本地副本，一遍点击prev然后点击next的时候，不会用重复的单词来upsetVocStudentOverviewDetail
        if (
          !this.currentViewedVocIdList.includes(
            this.vocList[this.vocCardItem.currentVocIndex].vocabularyId
          )
        ) {
          this.currentViewedVocIdList.push(
            this.vocList[this.vocCardItem.currentVocIndex].vocabularyId
          )
          //vocChanged 之后currentVocIndex会在vocChanged的函数里自增1; 现在的current是下一个，用当前的voc再发一个upsert去更新overview的detail
          this.upsertVocStudentOverviewDetail(true)
          this.isOverviewDetailsAdded = true
          return
        }
      }

      //this.vocCardItem.currentVocIndex += 1;
    },

    handleAutoPlay: function () {
      this.activateAutoPlay = !this.activateAutoPlay
      if (this.activateAutoPlay) {
        this.autoPlayInterval = setInterval(() => {
          this.handleNextClick()
          setTimeout(() => {
            this.handleVocClick()
          }, 2000)
        }, 5000)
      } else {
        this.clearAutoPlayInterval()
      }
    },

    clearAutoPlayInterval: function () {
      if (this.autoPlayInterval) clearInterval(this.autoPlayInterval)
    },

    handleEditCurrentWord: function () {
      this.showVocEditForm = true
    },

    updateTagsOnVocList: function (postData, tagName) {
      if (postData.deleted == true) {
        if (
          this.vocList[this.vocCardItem.currentVocIndex].vocabularyTags
            .length <= 0
        )
          return
        let index = this.vocList[
          this.vocCardItem.currentVocIndex
        ].vocabularyTags.findIndex(item => item.id == postData.vocTagId)
        this.vocList[this.vocCardItem.currentVocIndex].vocabularyTags.splice(
          index,
          1
        )
      } else {
        this.vocList[this.vocCardItem.currentVocIndex].vocabularyTags.push({
          id: postData.vocTagId,
          chineseName: tagName,
          englishName: null
        })
      }
      //修改本地单词的状态后，更新vocCachedList的状态
      this.updateVocInCachedList(this.vocList[this.vocCardItem.currentVocIndex])
    },

    onTagChange: function (status, item) {
      // 如果是学生想要在Andover的词汇上取消标签，则不执行
      if (
        this.userRole == 'Student' &&
        this.projectType == 0 &&
        status == false
      )
        return

      item.tag = status

      this.postTagChange(
        {
          vocabularyId:
            this.vocList[this.vocCardItem.currentVocIndex].vocabularyId,
          userId: this.targetId,
          vocTagId: item.id,
          deleted: !status
        },
        item.chineseName
      )
    },

    postTagChange: function (postData, tagName) {
      // 弹出comment input box
      if (postData.vocTagId == 10 && postData.deleted == false) {
        this.showCommentInputbox = true
        this.vocabularyComment = '混词 '
        this.$nextTick(() => {
          this.$refs.vocabularyCommentInputBoxEnCn.focus()
        })
      }

      this.$store
        .dispatch('Vocabulary/tagVocabulary', postData)
        .then(() => {
          this.updateTagsOnVocList(postData, tagName)
          if (postData.deleted == false && this.allowToAddHeartWithTag) {
            //在add标记之前添加overview detail，因为有时候标记会失败，但是overview却可以update数据，只是没有具体标记的词
            //标记单词时，在overview的detail里面去更新这个detail; isCorrect 是false，因为标记的词表明学生回答错误
            this.upsertVocStudentOverviewDetail(false)
            this.addOrCancelMark(true)
            this.allowToAddHeartWithTag = false
          }
        })
        .catch(error => console.log(error.toString()))
    },

    onProgressBarClicked: function (event) {
      var progressBarWidth = document.getElementById('progressBar').offsetWidth // use the total width instead of forfront bar to fix the bug of clicking progress bar
      //this.percent = (event.offsetX / progressBarWidth) * 100;
      this.vocChanged(
        Math.ceil(
          (event.offsetX / progressBarWidth) * this.vocCardItem.vocCount
        ) - 1
      )
    },

    gotoHome: function () {
      this.$router.push('/')
    },

    handleRefreshPage: function () {
      //不在使用页面的刷新，因为Naive全选的时候刷新很慢
      //this.$router.go(0);
      //使用vocCachedList来重制list一开始的状态
      this.vocList = this.vocCachedList
      this.initializeVocList()
      //重制vocList之后，manipulatingProcedure也要清空
      //清空cacheVocListInProcedureSteps
      this.manipulatingProcedure = []
      this.cacheVocListInProcedureSteps = []
      //新开一个card页面，拿到所有voc并初始化之后，post一条数据新建一个overview
      this.addStudentOverview()
    },

    handleHelp: function () {
      this.dialogHelpImageVisible = !this.dialogHelpImageVisible
    },

    //     handleHelp: function () {
    //       ElMessageBox.alert(
    //         `<table border="1">
    //           <tr>
    //             <td>快捷键</td>
    //             <td>作用</td>
    //             <td>备注</td>
    //           </tr>
    //           <tr>
    //             <td>Space（空格）</td>
    //             <td>翻转释义和单词</td>
    //             <td>翻到单词页时播放音频</td>
    //           </tr>
    //           <tr>
    //             <td>← （方向左键）</td>
    //             <td>上一个单词</td>
    //             <td>在第一个单词无效</td>
    //           </tr>
    //           <tr>
    //             <td>→ （方向右键）</td>
    //             <td>下一个单词</td>
    //             <td>在最后一个单词则跳转到第一个单词</td>
    //           </tr>
    //           <tr>
    //             <td>↑ （方向上键）</td>
    //             <td>翻转释义和单词</td>
    //             <td>翻到单词页时播放音频</td>
    //           </tr>
    //           <tr>
    //             <td>↓ （方向下键）</td>
    //             <td>翻转释义和单词</td>
    //             <td>翻到单词页时播放音频</td>
    //           </tr>
    //           <tr>
    //             <td>/ ? （斜杠/问号）</td>
    //             <td>给单词标心（加心）</td>
    //             <td>5心可继续标记为紫心</td>
    //           </tr>
    //           <tr>
    //             <td>-	（减号）</td>
    //             <td>给单词减心</td>
    //             <td>仅对老师有效</td>
    //           </tr>
    //           <tr>
    //             <td>·~（数字1左边的顿号）</td>
    //             <td>发音</td>
    //             <td></td>
    //           </tr>
    //           <tr>
    //             <td>h（heart）</td>
    //             <td>过滤所有心标词</td>
    //             <td>留下所有带心的词汇</td>
    //           </tr>
    //           <tr>
    //             <td>r（random）</td>
    //             <td>随机词汇集合</td>
    //             <td>对当前词汇集合做随机排序</td>
    //           </tr>
    //           <tr>
    //             <td>d</td>
    //             <td>词汇自定义过滤器</td>
    //             <td>根据范围选取心标和非心标词汇</td>
    //           </tr>
    //           <tr>
    //             <td>b（black）</td>
    //             <td>过滤黑色词汇</td>
    //             <td>留下所有显示为5个紫心的词汇</td>
    //           </tr>
    //           <tr>
    //             <td>m (mark)</td>
    //             <td>学生标星</td>
    //             <td>可自由标记和取消星标</td>
    //           </tr>
    //           <tr>
    //             <td>s (star)</td>
    //             <td>过滤学生专属星标</td>
    //             <td>仅过滤学生标记的星标，与心无关</td>
    //           </tr>
    //           <tr>
    //             <td>z (zero)</td>
    //             <td>过滤未标心词汇</td>
    //             <td>留下所有没有心的词汇</td>
    //           </tr>
    //           <tr>
    //             <td>v (Evian)</td>
    //             <td>Evian全局测试词汇</td>
    //             <td>301-1000随机100</td>
    //           </tr>
    //           <tr>
    //             <td>n (Naive)</td>
    //             <td>Naive强化全局测试</td>
    //             <td>全局随机100</td>
    //           </tr>
    //           <tr>
    //             <td>i (Naive)</td>
    //             <td>Naive冲刺全局测试</td>
    //             <td>全局随机50</td>
    //           </tr>
    //           <tr>
    //             <td>y (New Happy)</td>
    //             <td>New Happy冲刺全局测试</td>
    //             <td>全局随机50</td>
    //           </tr>
    //           <tr>
    //             <td>t (Test)</td>
    //             <td>阶段性测试</td>
    //             <td>选择当前集合中100个标记</td>
    //           </tr>
    //           <tr>
    //             <td>f (fiend)</td>
    //             <td>筛选多义词</td>
    //             <td>fiend不是friend</td>
    //           </tr>
    //           <tr>
    //             <td>l</td>
    //             <td>播放例句</td>
    //             <td>可能是常规例句或来自listening系统的句子</td>
    //           </tr>
    // </table>`,
    //         "VOC快捷键",
    //         {
    //           dangerouslyUseHTMLString: true,
    //           customStyle: "width: 35rem",
    //         }
    //       );
    //     },

    handlePlayVocVideo: function () {
      this.showVideoPlayer = !this.showVideoPlayer
    },

    handleTools: function () {
      this.showToolConfigForm = true
      document.removeEventListener('keydown', this.handleVocKeyDown) // 打开form的时候，移除keydown全局事件
    },

    handleDateFilter: function () {
      this.showDateFilter = true
    },

    //tool bar上的按钮，点击顺序切换中英文显示
    handleChangeCheckingModeClick: function () {
      let order = [1, 4, 2, 3]
      let orderIndex = order.findIndex(item => item === this.toolConfig.answer)
      if (orderIndex < order.length - 1) {
        this.toolConfig.answer = order[orderIndex + 1]
      } else {
        this.toolConfig.answer = 1
      }
      this.handleCheckingModeClick(this.toolConfig.answer)
      console.log(this.toolConfig.answer)
    },

    //处理tool上的checking mode图标被点击的时候事件
    handleCheckingModeClick: function (mode) {
      if (mode == 1) {
        this.showEnCn = false
        this.showMeaning = false
        this.showEn = true
      }
      if (mode == 2) {
        this.showEnCn = false
        this.showMeaning = true
        this.showEn = true
      }
      if (mode == 3) {
        this.showEnCn = true
        this.showEn = true
      }
      if (mode == 4) {
        this.showEnCn = true
        this.showEn = false
      }
      //设置当前单词做答模式
      this.toolConfig.answer = mode
    },

    //点击调用ai的api，生成对应的内容
    handleClickAIShortcut: function () {
      //如果目前正在请求deepseek，那就直接return
      if (this.deepseekIsLoading) return
      console.log('ai generator')
      //如果已经生成了内容，就直接显示，否则调用deepseek
      if (this.deepseekResponse.length > 0) {
        this.deepseekDialogVisible = true
      } else {
        ElMessage({
          message: '正在生成内容，请稍等...',
          type: 'success'
        })
        this.deepseekIsLoading = true
        let vocListRaw = this.vocList
          .map(item => item.englishText)
          .slice(0, 50)
          .join(',')
        let userContent = `请用给出的50个词，以英语中最常用的1000个词为基础，写一个给中国初一学生看的英语小故事。这50个词是：${vocListRaw}`
        //getDeepSeekResponse
        this.$store
          .dispatch('DeepSeek/getDeepSeekResponse', {
            model: 'deepseek-v3',
            //model: "deepseek-chat",
            messages: [{ role: 'user', content: userContent }]
          })
          .then(response => {
            ElMessage({
              message: 'content generated',
              type: 'success'
            })
            this.deepseekResponse = response.data.choices[0].message.content
            this.deepseekDialogVisible = true
            this.deepseekIsLoading = false
          })
          .catch(error => console.log(error.toString()))
      }
    },

    handleToolConfigFormClose: function () {
      this.showToolConfigForm = false
      document.addEventListener('keydown', this.handleVocKeyDown) //关闭form的时候，加入keydown全局事件
      // this.toolConfig.random = value.random;
      // this.toolConfig.audio = value.audio;
      // this.starred = value.starred;
      // this.answer = value.answer;
    },

    initializeVocList: function () {
      this.vocCardItem.currentVocIndex = 0
      this.vocCardItem.vocCount = this.vocList.length
      if (this.vocList.length <= 0) {
        this.vocList.push({
          englishText: '',
          chineseText: '',
          count: 0,
          marked: false,
          studentMarked: false,
          vocabularyTags: []
        })
      }
      this.vocCardItem.currentVocLevel = this.getCurrentVocLevel()
      //如果单词有多个意思，则给出提示 - 0是current index -start
      this.openMultiMeaningsNotice(0)
      //如果单词有多个意思，则给出提示-end
      this.percentage =
        ((this.vocCardItem.currentVocIndex + 1) / this.vocCardItem.vocCount) *
        100
      this.initializeTags()
      //每次初始化的时候，重置current marked vocs
      this.handleClearCurrentMarkedVocs()

      //如果有单词里面有spelling，就对比一下spelling里面的值，看对错
      this.compareSpelling()

      //给每个单词加上切分音节的属性，syllables
      this.splitSyllables()
    },

    splitSyllables: function () {
      if (!this.vocList[this.vocCardItem.currentVocIndex].englishText.trim())
        return
      let currentWord =
        this.vocList[this.vocCardItem.currentVocIndex].englishText
      let result = preProcessedWords[currentWord]
        ? preProcessedWords[currentWord]
        : syllables(currentWord)

      //超过一个音节，才显示
      if (result.length > 1) {
        this.vocList[this.vocCardItem.currentVocIndex].syllables =
          result.join('-')
      }

      //如果是词组比如figure out，syllables输出是[['fi','gure'],['out']]; 所以最终输出fi,gure-out
      //替换逗号变成-
      if (
        this.vocList[this.vocCardItem.currentVocIndex].syllables &&
        this.vocList[this.vocCardItem.currentVocIndex].syllables.includes(',')
      ) {
        this.vocList[this.vocCardItem.currentVocIndex].syllables = this.vocList[
          this.vocCardItem.currentVocIndex
        ].syllables.replace(/,/g, '-')
      }
    },

    compareSpelling: function () {
      if (!this.vocList[this.vocCardItem.currentVocIndex].spelling) return
      let currentVoc = this.vocList[this.vocCardItem.currentVocIndex]
      //如果学生没有作答这个词，not answered，就改写成空字符
      currentVoc.spelling =
        currentVoc.spelling === 'not answered' ? '' : currentVoc.spelling
      currentVoc.comparedSpelling = []

      currentVoc.comparedSpelling = this.compareSpellingComplex(
        currentVoc.englishText,
        currentVoc.spelling
      )

      // for (let i = 0; i < currentVoc.spelling.length; i++) {
      //   const char1 = currentVoc.englishText[i] || "";
      //   const char2 = currentVoc.spelling[i] || "";
      //   currentVoc.comparedSpelling.push({
      //     char: char2,
      //     correct: char1 === char2,
      //   });
      // }
    },

    //compareSpellingComplex 该函数credits to ChatGPT
    compareSpellingComplex: function (englishText, userSpelling) {
      const m = englishText.length
      const n = userSpelling.length

      const dp = Array.from({ length: m + 1 }, () => Array(n + 1).fill(0))

      for (let i = 0; i <= m; i++) {
        for (let j = 0; j <= n; j++) {
          if (i === 0) {
            dp[i][j] = j
          } else if (j === 0) {
            dp[i][j] = i
          } else if (englishText[i - 1] === userSpelling[j - 1]) {
            dp[i][j] = dp[i - 1][j - 1]
          } else {
            dp[i][j] =
              1 + Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1])
          }
        }
      }

      let i = m
      let j = n
      const comparedSpelling = []

      while (i > 0 || j > 0) {
        if (i > 0 && dp[i][j] === dp[i - 1][j] + 1) {
          comparedSpelling.push({
            char: englishText[i - 1],
            errorType: 'deletion'
          })
          i--
        } else if (j > 0 && dp[i][j] === dp[i][j - 1] + 1) {
          comparedSpelling.push({
            char: userSpelling[j - 1],
            errorType: 'insertion'
          })
          j--
        } else if (dp[i][j] === dp[i - 1][j - 1] + 1) {
          comparedSpelling.push({
            char: userSpelling[j - 1],
            errorType: 'substitution'
          })
          i--
          j--
        } else {
          comparedSpelling.push({
            char: userSpelling[j - 1],
            errorType: 'correct'
          })
          i--
          j--
        }
      }

      return comparedSpelling.reverse()
    },

    //     这段代码是一个用于比较两个字符串之间编辑距离的函数，编辑距离是衡量两个字符串相似程度的一种方法，即通过最少的插入、删除和替换操作，将一个字符串转换成另一个字符串所需的步骤数。

    // 下面是代码的主要部分及其功能：

    // 1. `compareSpelling(englishText, userSpelling)`: 这是一个函数，接受两个字符串作为参数，分别是正确的英文单词 `englishText` 和用户拼写的单词 `userSpelling`。函数返回一个数组，表示将用户拼写的单词转换成正确的单词所需的编辑操作。

    // 2. 创建一个二维数组 `dp` 来存储编辑距离，其中 `dp[i][j]` 表示将 `englishText` 的前 `i` 个字符转换成 `userSpelling` 的前 `j` 个字符所需的最小操作数。

    // 3. 使用动态规划填充 `dp` 数组，根据以下规则更新 `dp[i][j]` 的值：
    //    - 若 `i` 等于 0，则 `dp[i][j]` 等于 `j`，表示将空字符串转换成 `userSpelling` 的操作数。
    //    - 若 `j` 等于 0，则 `dp[i][j]` 等于 `i`，表示将 `englishText` 转换成空字符串的操作数。
    //    - 若 `englishText[i - 1]` 等于 `userSpelling[j - 1]`，则 `dp[i][j]` 等于 `dp[i - 1][j - 1]`，表示当前字符相同，无需操作。
    //    - 否则，`dp[i][j]` 等于 `1 + Math.min(dp[i - 1][j], dp[i][j - 1], dp[i - 1][j - 1])`，表示当前字符不同，需要进行插入、删除或替换操作中的最小操作数。

    // 4. 通过回溯找出具体的编辑操作，从 `dp[m][n]` 开始向前遍历，根据 `dp` 数组的值确定具体的操作：
    //    - 若 `dp[i][j] === dp[i - 1][j] + 1`，表示当前操作是删除操作。
    //    - 若 `dp[i][j] === dp[i][j - 1] + 1`，表示当前操作是插入操作。
    //    - 否则，当前操作是替换操作。

    // 5. 将编辑操作存储在 `comparedSpelling` 数组中，并最终返回该数组。

    // 6. 示例用法展示了如何比较正确的英文单词 "further" 和用户拼写的单词 "futher"，并输出了编辑操作数组。

    // 这段代码利用动态规划算法实现了计算编辑距离，并通过回溯找出具体的编辑操作，从而帮助比较两个字符串之间的相似度。

    randomArray: function (arrayList) {
      if (arrayList.length === 0 || arrayList.length === null) return
      // outdated 这个随机算法可能导致有些单词没有被触碰，所以顺序仍然一致
      // for (var i = 0; i < arrayListLength; i++) {
      //   var randomIndex = Math.floor(Math.random() * arrayList.length);
      //   var tempItem = arrayList[randomIndex];
      //   arrayList[randomIndex] = arrayList[0];
      //   arrayList[0] = tempItem;
      // }

      //优化后的随机算法，随机更彻底，但是性能降低，因为每次push一个词就要在源数组删掉这个词，影响该词之后所有词的顺序
      // let resultArray = [];
      // while(arrayList.length > 0) {
      //   let randomIndex = Math.floor(Math.random() * arrayList.length);
      //   resultArray.push(arrayList[randomIndex]);
      //   // 将随机下标的元素值用最后一个值替换，然后pop最后一个值，提升性能
      //   //arrayList.splice(randomIndex, 1);
      //   arrayList[randomIndex] = arrayList[arrayList.length - 1];
      //   arrayList.pop();
      // }

      // 继续优化，让random的元素和第i个元素换位置；这样就不需要临时变量resultArray了
      for (var i = 0; i < arrayList.length; i++) {
        var randomIndex = Math.floor(Math.random() * arrayList.length)
        var tempItem = arrayList[randomIndex]
        arrayList[randomIndex] = arrayList[i]
        arrayList[i] = tempItem
      }
      return arrayList
    },

    shuffleVoc: function () {
      console.log(this.manipulatingProcedure)
      // 缓存voc list； 更新后使用vocCachedList来缓存一开始的list
      Object.assign(this.preVocListScreenShot, this.vocList)
      this.initializeVocList()
      this.vocList = this.randomArray(this.vocList)
      this.$forceUpdate()
      //操作过随机voc之后，将随机操作记录到manipulatingProcedure
      this.updateManipulatingProcedure('随机')
    },

    updateManipulatingProcedure: function (manipulation) {
      if (!this.manipulatingProcedure) return
      if (this.manipulatingProcedure.length == 0) {
        this.manipulatingProcedure.push(manipulation)
        this.cacheVocListInProcedureSteps.push(this.vocList)
        return
      }
      //如果最后一个操作内容和传入的内容一致，则不存入字符串manipulation
      if (
        this.manipulatingProcedure[this.manipulatingProcedure.length - 1] ==
        manipulation
      )
        return
      this.manipulatingProcedure.push(manipulation)
      this.cacheVocListInProcedureSteps.push(this.vocList)
    },

    cookManipulatingProcedureString: function (formData) {
      //如果选了心，就把心的数量转成string然后push进去
      if (
        formData.checkedHeart.length > 0 ||
        formData.checkedTags.length > 0 ||
        (formData.startIndex && formData.endIndex)
      ) {
        //此处的3个不同类型字符串的？运算符要用括号括起来，不然只会执行第一个条件，因为：后面的运算不只是‘’，而是会把后面所有的都连接起来当成整体
        let manipulatingProcedureStringFromForm =
          (formData.checkedHeart.length > 0
            ? 'Heart: ' + formData.checkedHeart.toString() + '; '
            : '') +
          (formData.checkedTags.length > 0
            ? formData.checkedTags.map(id => {
                let found = formData.allTags.find(cn => cn.id === id)
                return found.chineseName
              }) + '; '
            : '') +
          (formData.startIndex && formData.endIndex
            ? 'Range: ' +
              (formData.startIndex ? formData.startIndex : '') +
              ' - ' +
              (formData.endIndex ? formData.endIndex : '')
            : '')
        this.updateManipulatingProcedure(manipulatingProcedureStringFromForm)
      }
      console.log(this.manipulatingProcedure)
    },

    handleRandomSwitchChange: function (val) {
      this.toolConfig.random = val
      if (this.toolConfig.random) {
        this.shuffleVoc()
      } else {
        Object.assign(this.vocList, this.preVocListScreenShot)
      }
    },

    handleAudioSwitchChange: function (val) {
      this.toolConfig.audio = val
    },

    handleStarredSwitchChange: function (val) {
      this.toolConfig.starred = val
    },

    handleOnToolConfigFormConfirm: function (formData) {
      if (formData.answer == 1) {
        this.showEnCn = false
        this.showMeaning = false
        this.showEn = true
      }
      if (formData.answer == 2) {
        this.showEnCn = false
        this.showMeaning = true
        this.showEn = true
      }
      if (formData.answer == 3) {
        this.showEnCn = true
        this.showEn = true
      }
      if (formData.answer == 4) {
        this.showEnCn = true
        this.showEn = false
      }
      console.log(formData)
      this.filterVocListByTags(formData.checkedTags)
      this.filterVocListByHeart(formData.checkedHeart)
      this.filterVocListByRange(formData.startIndex, formData.endIndex)
      // cache voc list
      this.preVocListScreenShot = []
      Object.assign(this.preVocListScreenShot, this.vocList)

      this.handleRandomSwitchChange(formData.random)

      //处理完vocList后，整理一下form里的参数，写到操作步骤中
      this.cookManipulatingProcedureString(formData)
    },

    autoSetProcess: function (processCode) {
      switch (processCode) {
        case 1:
          this.shuffleVoc()
          break
        case 2:
          this.filterAllMarkedVoc()
          break
        case 3:
          this.filterVocListWithNoHeart()
          break
        case 4:
          this.filterVocByMarkedCount(6)
          break
        case 5:
          this.filterVocListByRange(
            this.decodedQueryObject.from,
            this.decodedQueryObject.to
          )
          break
        case 6:
          break
      }

      this.handleCheckingModeClick(this.decodedQueryObject.examMode)
    },

    //关闭自定义词汇选取的时候
    handleOnCustomizedFilterFormClose: function () {
      this.showCustomizedFilterForm = false
      //关闭窗口的时候，加入全局事件；同时在handleOnCustomizedFilterFormConfirm函数也需要
      document.addEventListener('keydown', this.handleVocKeyDown)
    },

    //定制连个范围，根据范围里的词，再选取星标和非星标特定的个数，组成一个完成voc list
    handleOnCustomizedFilterFormConfirm: function (formData) {
      document.addEventListener('keydown', this.handleVocKeyDown)
      this.showCustomizedFilterForm = false
      // 用randomArray（list） 先随机部分；生成两个lists
      if (
        formData == null ||
        formData.startRange1 == 0 ||
        formData.endRange1 == 0
      ) {
        //console.log("both from and to should not be null");
        return
      } else {
        if (formData.startRange1 > formData.endRange1) {
          let temp = formData.startRange1
          formData.startRange1 = formData.endRange1
          formData.endRange1 = temp
        }

        let firstRange =
          this.vocList.slice(formData.startRange1 - 1, formData.endRange1) || []
        //shuffle first range
        firstRange = this.randomArray(firstRange)

        if (formData.startRange2 > formData.endRange2) {
          let temp = formData.startRange2
          formData.startRange2 = formData.endRange2
          formData.endRange2 = temp
        }
        let secondRange =
          this.vocList.slice(formData.startRange2 - 1, formData.endRange2) || []
        //shuffle second range
        secondRange = this.randomArray(secondRange)

        //过滤两个range中的星标和非星标词数组；根据formData中的数值继续截取
        let markedInFirstRange = firstRange
          ?.filter(item => item.marked == true)
          ?.slice(0, formData.staredCount1)
        let unmarkedInFirstRange = firstRange
          ?.filter(item => item.marked == false)
          ?.slice(0, formData.unstaredCount1)
        let markedInSecondRange = secondRange
          ?.filter(item => item.marked == true)
          ?.slice(0, formData.staredCount2)
        let unmarkedInSecondRange = secondRange
          ?.filter(item => item.marked == false)
          ?.slice(0, formData.unstaredCount2)
        let result = (markedInFirstRange || []).concat(
          unmarkedInFirstRange || [],
          markedInSecondRange || [],
          unmarkedInSecondRange || []
        )
        result = this.randomArray(result)
        // cache voc list
        this.preVocListScreenShot = []
        Object.assign(this.preVocListScreenShot, this.vocList)
        this.vocList = result
        //初始化过滤出的list
        this.initializeVocList()
      }
    },

    //定制阶段性词测选择100个标记的快捷键，比如选中Naive1-8，过滤出其中100个标记，快捷键t-start
    MilestoneTestShortcut: function () {
      this.handleOnCustomizedFilterFormConfirm({
        startRange1: 1,
        endRange1: 3000,
        staredCount1: 100,
        staredCount2: 0,
        unstaredCount1: 0,
        startRange2: 0,
        endRange2: 0,
        unstaredCount2: 0
      })

      ElMessage({
        type: 'success',
        message: `已选中当前集合中的${this.vocList.length}个标记`
      })
    },
    //定制阶段性词测选择100个标记的快捷键，比如选中Naive1-8，过滤出其中100个标记-end

    //定制全局词汇测试快捷键，以便省去每次配置参数的过程-start
    EvianTestShortcut: function () {
      if (
        this.decodedQueryObject.subjectName != 'EVIAN' ||
        this.vocCardItem.vocCount < 1000
      )
        return
      this.filterVocListByRange(301, 1004)
      this.shuffleVoc()
      this.filterVocListByRange(1, 100)
      // this.handleOnCustomizedFilterFormConfirm({
      //   startRange1: 301,
      //   endRange1: 1000,
      //   staredCount1: 0,
      //   staredCount2: 0,
      //   unstaredCount1: 100,
      //   startRange2: 0,
      //   endRange2: 0,
      //   unstaredCount2: 0,
      // });
      ElMessage({
        type: 'success',
        message: 'Evian全局测试'
      })
    },

    //Naive lists的随机100测试，快捷键 n
    NaiveTestShortcutPre: function () {
      if (
        this.decodedQueryObject.subjectName != 'NAIVE' ||
        this.vocCardItem.vocCount < 1801
      )
        return
      // this.filterVocListByRange(1, 1000);
      this.shuffleVoc()
      this.filterVocListByRange(1, 100)
      // this.handleOnCustomizedFilterFormConfirm({
      //   startRange1: 1,
      //   endRange1: 1801,
      //   staredCount1: 0,
      //   staredCount2: 0,
      //   unstaredCount1: 100,
      //   startRange2: 0,
      //   endRange2: 0,
      //   unstaredCount2: 0,
      // });
      ElMessage({
        type: 'success',
        message: 'Naive强化全局测试'
      })
    },

    //Naive全部随机50，用于冲刺班第一阶段测试，快捷键 i
    NaiveTestShortcutPrePost: function () {
      if (
        this.decodedQueryObject.subjectName != 'NAIVE' ||
        this.vocCardItem.vocCount < 1801
      )
        return
      this.shuffleVoc()
      this.filterVocListByRange(1, 50)
      // this.handleOnCustomizedFilterFormConfirm({
      //   startRange1: 1,
      //   endRange1: 1801,
      //   staredCount1: 0,
      //   staredCount2: 0,
      //   unstaredCount1: 75,
      //   startRange2: 0,
      //   endRange2: 0,
      //   unstaredCount2: 0,
      // });
      ElMessage({
        type: 'success',
        message: 'Naive冲刺全局测试'
      })
    },

    //New Happy全部随机50，用于冲刺班第二阶段测试，快捷键 i
    NewHappyGlobal: function () {
      if (
        this.decodedQueryObject.subjectName != 'HAPPY' ||
        this.vocCardItem.vocCount < 1200
      )
        return
      this.shuffleVoc()
      this.filterVocListByRange(1, 50)
      // this.handleOnCustomizedFilterFormConfirm({
      //   startRange1: 1,
      //   endRange1: 1200,
      //   staredCount1: 0,
      //   staredCount2: 0,
      //   unstaredCount1: 50,
      //   startRange2: 0,
      //   endRange2: 0,
      //   unstaredCount2: 0,
      // });
      ElMessage({
        type: 'success',
        message: 'Happy冲刺全局测试'
      })
    },

    //定制全局词汇测试快捷键，以便省去每次配置参数的过程-end

    filterVocListByTags: function (tags) {
      if (!tags || tags.length <= 0) return
      // this.vocList = this.vocList.filter((item) => {
      //   for (let i = 0; i < tags.length; i++) {
      //     // console.log(tags)
      //     // console.log(item.vocabularyTags)
      //     return item.vocabularyTags
      //       .map((item) => {
      //         return item.id;
      //       })
      //       .includes(tags[i]);
      //     // for (let j = 0; j < item.vocabularyTags.length; i++) {
      //     //   //console.log(item.vocabularyTags[j] == tags[i])
      //     //   return item.vocabularyTags[j].id == tags[i];
      //     // }
      //   }
      // });
      let tempVocList = []
      for (let i = 0; i < this.vocList.length; i++) {
        for (let j = 0; j < this.vocList[i].vocabularyTags.length; j++) {
          if (tags.includes(this.vocList[i].vocabularyTags[j].id)) {
            tempVocList.push(this.vocList[i])
            break
          }
        }
      }
      this.vocList = tempVocList
      this.initializeVocList()
    },

    filterAllMarkedVoc: function () {
      this.vocList = this.vocList.filter(item => item.marked == true)
      this.initializeVocList()
      //过滤标心词汇后，记录操作步骤
      this.updateManipulatingProcedure('心标')
    },

    filterVocByMarkedCount: function (count) {
      this.vocList = this.vocList.filter(
        item => item.marked == true && item.count >= count
      )
      this.initializeVocList()

      //过滤黑色词汇后，记录操作步骤
      this.updateManipulatingProcedure('黑色')
    },

    filterVocListByHeart: function (checkedHeart) {
      if (!checkedHeart || checkedHeart.length <= 0) return
      let sortedCheckedHeart = checkedHeart.sort((a, b) => a - b)
      //vocList的count如果是0，那就marked是false，如果sortedCheckedHeart包含了0，那也要把没有标记的词筛选出来
      this.vocList = this.vocList.filter(item => {
        return (
          (item.marked == true &&
            (sortedCheckedHeart.includes(item.count) ||
              (sortedCheckedHeart[sortedCheckedHeart.length - 1] === 6
                ? item.count > 6
                : false))) || //如果checkedHeart里面有0，那就筛选出count为0的词
          (item.marked == false && sortedCheckedHeart.includes(0))
        )
      })

      this.vocCardItem.currentVocIndex = 0 // reset index
      this.vocCardItem.vocCount = this.vocList.length // reset voc list count
    },

    filterVocListWithNoHeart: function () {
      this.vocList = this.vocList.filter(item => {
        return item.marked == false
      })

      this.vocCardItem.currentVocIndex = 0 // reset index
      this.vocCardItem.vocCount = this.vocList.length // reset voc list count

      //过滤后，记录操作步骤
      this.updateManipulatingProcedure('空心')
    },

    filterVocListByRange: function (vocFromIndex, vocToIndex) {
      if (
        vocFromIndex == null ||
        vocFromIndex == '' ||
        vocToIndex == null ||
        vocToIndex == ''
      ) {
        //console.log("both from and to should not be null");
        return
      } else {
        if (vocFromIndex > vocToIndex) {
          let temp = vocFromIndex
          vocFromIndex = vocToIndex
          vocToIndex = temp
        }
        this.vocCardItem.currentVocIndex = 0
        this.vocList = this.vocList.slice(vocFromIndex - 1, vocToIndex)
        this.vocCardItem.vocCount = this.vocList.length
      }
    },

    filterStudentMarkedVoc: function () {
      this.vocList = this.vocList.filter(item => item.studentMarked)
      this.initializeVocList()

      //过滤标心词汇后，记录操作步骤；星是学生的标记
      this.updateManipulatingProcedure('星标')
    },

    filterVocListWithMultiMeaning: function () {
      this.vocList = this.vocList.filter(item => this.hasMultiMeaning(item))
      this.initializeVocList()
      //过滤多义词后，记录操作步骤；步骤是 筛选多义词
      this.updateManipulatingProcedure('筛选多义词')
    },

    hasMultiMeaning: function (voc) {
      let multiMarker = ['②', '③', '④', '⑤', '⑥', '⑦', '⑧', '⑨']
      for (let i = 0; i < multiMarker.length; i++) {
        if (voc.chineseText.includes(multiMarker[i])) {
          return true
        }
      }
      return false
    },

    handleAnswerRadioGroupChange: function (val) {
      this.toolConfig.answer = val
    },

    handleVocUpdateClick: function (index) {
      this.showVocEditForm = true
      this.vocChanged(index)
    },

    handleVocEditFormConfirm: function (formData) {
      this.showVocEditForm = false
      this.vocList[this.vocCardItem.currentVocIndex].englishText =
        formData.english
      this.vocList[this.vocCardItem.currentVocIndex].chineseText =
        formData.chinese
    },

    handleDateFilterFormConfirm: function (daterange) {
      if (daterange == null || daterange.length <= 0) return
      //console.log(daterange);
      this.showDateFilter = false
      this.vocList = this.vocList.filter(item => {
        // let markVocUpdatedDate = new Date(Date.parse(item.markUpdatedDate));
        // console.log(markVocUpdatedDate)
        // console.log( markVocUpdatedDate >= daterange[0] && markVocUpdatedDate <= daterange[1])
        return (
          item.markUpdatedDate != null &&
          new Date(Date.parse(item.markUpdatedDate)) >= daterange[0] &&
          new Date(Date.parse(item.markUpdatedDate)) <= daterange[1]
        )
      })
      this.vocCardItem.currentVocIndex = 0
      //console.log(this.vocList);
      this.vocCardItem.vocCount = this.vocList.length
    },

    handleVocDelete: function (index, vocId) {
      this.$store
        .dispatch('Vocabulary/delete', {
          vocabularyId: vocId
        })
        .then(() => {
          this.vocList.splice(index, 1)
          this.vocCardItem.vocCount = this.vocList.length
        })
        .catch(error => console.log(error.toString()))
    },

    addOneVoc: function () {
      if (this.addOneEnglish == '' && this.addOneChinese == '') return
      //console.log(this.decodedQueryObject);
      this.$store
        .dispatch('Vocabulary/add', {
          vocSetId: this.decodedQueryObject.setIds[0],
          englishText: this.addOneEnglish,
          chineseText: this.addOneChinese,
          updatedBy: this.userId
        })
        .then(response => {
          ElMessage({
            message: 'new vocabulary added',
            type: 'success'
          })
          this.vocList.push({
            vocabularyId: response.data,
            englishText: this.addOneEnglish,
            chineseText: this.addOneChinese,
            updatedBy: this.userId,
            marked: false,
            count: 0,
            vocabularyTags: []
          })
          this.vocCardItem.vocCount = this.vocList.length
          // reset input boxes
          this.addOneEnglish = ''
          this.addOneChinese = ''
        })
        .catch(error => console.log(error.toString()))
    },

    handleDuplicateEnglishText: function () {
      const textAreaTemp = document.createElement('textarea')
      textAreaTemp.textContent = this.generateTextForCopy()
      document.body.append(textAreaTemp)
      textAreaTemp.select()
      document.execCommand('copy')
    },

    generateTextForCopy: function () {
      if (this.selectedVocObjects.length == 0) return
      return this.selectedVocObjects
        .map(item => {
          return (
            item.englishText +
            ' ' +
            item.vocabularyTags
              .map(item => {
                return item.chineseName
              })
              .join('; ')
          )
        })
        .join('\n')
    },

    concatTags: function (tags) {
      if (tags == null || tags.length <= 0) return
      return tags.join('; ')
    },

    handleSelectionChange: function (selection) {
      this.selectedVocObjects = selection
    },

    handleEnglishTextColumnClick: function (event, englishText) {
      this.$playAudio(englishText, this.$refs.vocaudio)
    },

    handleEnterOnCommentInputbox: function () {
      //console.log(this.vocabularyComment);
      // 在上传comments成功后，隐藏这个inputbox
      // let teacherComment = "";
      // let studentComment = "";
      // if (this.userRole == "Student") {
      //   studentComment = this.vocabularyComment;
      // } else {
      //   teacherComment = this.vocabularyComment;
      // }
      this.$store
        .dispatch('Vocabulary/commentVocabulary', {
          userId: this.targetId,
          vocabularyId:
            this.vocList[this.vocCardItem.currentVocIndex].vocabularyId,
          studentComment: this.vocabularyComment,
          updatedByTeacher: this.userId
        })
        .then(() => {
          ElMessage({
            message: 'Comment Added',
            type: 'success'
          })
          this.showCommentInputbox = false
          //update current voc comment
          //this.vocList[this.vocCardItem.currentVocIndex].teacherComment = teacherComment;
          this.vocList[this.vocCardItem.currentVocIndex].studentComent =
            this.vocabularyComment
        })
        .catch(error => console.log(error.toString()))
    },

    //拿到Evian和Naive的单词，然后用当前词表所有的词和Evian，Naive做匹配，看哪些词来自词表
    handleMapIt: function () {
      if (
        this.decodedQueryObject.subjectName == 'EVIAN' ||
        this.decodedQueryObject.subjectName == 'NAIVE'
      )
        return
      let setIds = []
      if (
        this.decodedQueryObject.subjectName == 'TPO词汇题' ||
        this.decodedQueryObject.subjectName == 'UFO词汇题'
      ) {
        //TPO和UFO的词汇题和机经词频匹配，这里需要机经词频的所有set id
        setIds = [
          528, 535, 536, 537, 538, 539, 540, 541, 542, 543, 545, 546, 547, 549,
          550, 551, 552, 553, 554, 557
        ]
      } else {
        //前20个是EVIAN的set Id，后36个是Naive的set id
        setIds = [
          425, 447, 477, 478, 479, 480, 487, 488, 489, 490, 492, 493, 494, 495,
          496, 497, 498, 499, 500, 501, 245, 250, 255, 271, 288, 290, 345, 358,
          360, 362, 363, 366,

          373, 374, 375, 376, 378, 379, 381, 382, 383, 384, 385, 386, 388, 396,
          398, 399, 400, 401, 402, 404, 405, 406, 408, 409
        ]
      }

      let params = {
        userId: this.targetId,
        setIds: setIds
      }

      this.$store
        .dispatch('Vocabulary/getMarkedVocabularies', params)
        .then(response => {
          for (let i = 0; i < this.vocList.length; i++) {
            let currentMapping = response.data.find(
              vItem =>
                vItem.englishText.trim().toLowerCase() ==
                this.vocList[i].englishText.trim().toLowerCase()
            )
            if (currentMapping) {
              this.vocList[i].mappingWord = currentMapping
            }
          }
          ElMessage({
            type: 'success',
            message: 'Rosetta Stone Matched~~~'
          })
        })
        .catch(error => console.log(error.toString()))
    },

    handleClearCurrentMarkedVocs: function () {
      this.currentMarkedVocs = []
    },

    getListeningArticleByTitle: function () {
      //必须是只从一个set进入的时候，否则无法判断当前的唯一文章名字
      //而且必须是从panoramic词表进入的时候，也就是subjectName得对应
      if (
        this.decodedQueryObject.setIds.length != 1 ||
        this.decodedQueryObject.subjectName != 'Panoramic听力词汇'
      )
        return
      //如果当前listening文章句子不存在，则去拿
      if (!this.currentListeningArticleWithSentences) {
        this.$store
          .dispatch('Admin/getListeningArticleByTitle', {
            title: this.decodedQueryObject.setName.toLowerCase()
          })
          .then(response => {
            console.log(response.data)
            //this.getSentencesByListeningArticleId(response.data[0].Id);
            this.currentListeningArticleWithSentences = response.data[0]
            this.mapVocabularyToSentence()
          })
          .catch(error => {
            console.log(error.toString())
          })
      } else {
        this.mapVocabularyToSentence()
      }
    },

    // getSentencesByListeningArticleId: function (id) {
    //   this.$store
    //     .dispatch("Admin/getSentencesByArticleId", {
    //       articleId: id,
    //     })
    //     .then((response) => {
    //       //拿到的该article id下的所有句子存入本地的article sentences
    //       this.currentListeningArticleWithSentences = response.data;
    //       //将当前单词和listening文章中的句子匹配
    //       this.mapVocabularyToSentence();
    //     })
    //     .catch((error) => {
    //       console.log(error.toString());
    //     });
    // },

    mapVocabularyToSentence: function () {
      if (!this.currentListeningArticleWithSentences) return
      let sentences = this.currentListeningArticleWithSentences.sentences
      for (let i = 0; i < sentences.length; i++) {
        if (
          sentences[i].englishText
            .toLowerCase()
            .includes(
              this.vocList[this.vocCardItem.currentVocIndex].englishText.trim()
            )
        ) {
          this.$refs.vocaudio.src =
            'https://vocabulary.andover.com.cn/vocabulary/audios/listeningAudios/' +
            this.currentListeningArticleWithSentences.title +
            '/' +
            sentences[i].audioPath +
            '.mp3'
          this.$refs.vocaudio.play()
          break
        }
      }
    },

    //操作plan的task和result
    handleAddTaskResultClick: function () {
      this.dataFromCard.taskInfo = this.decodedQueryObject
      this.dataFromCard.resultInfo = {}
      this.dataFromCard.resultInfo.generatedResult = this.generateTaskResults()
      this.dataFromCard.resultInfo.incorrects = this.currentMarkedVocs
        .map(item => item.englishText)
        .join(', ')
      this.dataFromCard.resultInfo.incorrectCount =
        this.currentMarkedVocs.length
      this.dataFromCard.resultInfo.checkedCount =
        this.vocCardItem.currentVocIndex + 1
      //开启result页面的时候，说明检查结束；记录一个结束时间;用当前时间减去start time就是总耗时
      let endTime = new Date()
      this.dataFromCard.resultInfo.timeConsumed = this.getTimeConsumed(
        endTime,
        this.checkingStartTime
      )
      this.showCreatePlanDetailTaskResultForm = true
    },

    getTimeConsumed: function (end, start) {
      let differenceInMinute =
        (Number(end.getTime()) - Number(start.getTime())) / 60000

      let roundedDifference = differenceInMinute.toFixed(2)
      return roundedDifference
    },

    uniqueCurrentMarkedVoc: function () {
      this.currentMarkedVocs = this.currentMarkedVocs.filter(item => {
        // Create a helper object to store unique IDs
        const uniqueIds = {}
        // Return true if the ID is encountered for the first time
        if (!uniqueIds[item.id]) {
          uniqueIds[item.id] = true
          return true
        }
        return false
      })
    },

    handlePlanFormDetailTaskResultConfirm: function () {
      this.showCreatePlanDetailTaskResultForm = false
      //确认添加了result之后，单次移除result from，防止重复添加；再加需要刷新
      this.isPlanDetailTask = false
      this.decodedQueryObject.taskId = null
      //提交result之后，isExaminingAsStudent的值要变成false
      this.handleDeactivateExamine(this.currentTask)
    },

    /////////$$$$$ 添加标记的错词的完整逻辑 - 开始 ////////
    //把当前标记过的词添加到DIY的错词下的 don't worry be happy下的当前日期命名的set
    handleAddIncorrectWords: function () {
      this.getDIYProjects()
    },

    getDIYProjects: function () {
      this.$store
        .dispatch('Project/getProject', {
          userId: this.targetId,
          type: 1
        })
        .then(response => {
          this.diyprojects = response.data
          this.diyprojects = this.diyprojects.filter(item => !item.teacherName)
          this.findOrCreateIncorrectVocProject()
          //this.processProjectsData();
        })
    },

    findOrCreateIncorrectVocProject: function () {
      let incorrectVocProject = this.diyprojects.find(
        item => item.name === '错词'
      )
      //如果没找到就创建一个project
      if (!incorrectVocProject) {
        this.$store
          .dispatch('Project/addProject', {
            userId: this.targetId,
            type: 1,
            name: '错词'
          })
          .then(response => {
            ElMessage({
              message: '添加项目.',
              type: 'success'
            })
            this.currentDIYProjectId = response.data
            this.createIncorrectVocProjectSubject(response.data)
          })
          .catch(error => console.log(error))
      } else {
        this.currentDIYProjectId = incorrectVocProject.id
        //如果project里面的vocSubjects没有内容，就创建
        if (incorrectVocProject.vocSubjects.length <= 0) {
          this.createIncorrectVocProjectSubject(this.currentDIYProjectId)
        } else {
          //找当前project下的第一个subject
          this.currentDIYSubjectId = incorrectVocProject.vocSubjects[0].id
          this.getSetsBySubjectId(this.currentDIYSubjectId).then(response => {
            let todaySetName = this.generateCurrentDateString()
            let findTodaySet = response.data.find(
              item => item.name === todaySetName
            )
            //如果找到了今天的，就设定找到的set作为添加的词汇集
            if (findTodaySet) {
              this.currentDIYSetId = findTodaySet.id
              this.addIncorrectWordsToSet()
            } else {
              this.createAndBindIncorrectVocProjectSubjectSet(
                this.currentDIYSubjectId
              )
            }
          })
        }
      }
    },

    createIncorrectVocProjectSubject: function (projectId) {
      if (!projectId) return
      this.$store
        .dispatch('Subject/add', {
          vocProjectId: projectId,
          name: "Don't worry; be happy~"
        })
        .then(response => {
          ElMessage({
            message: '添加科目.',
            type: 'success'
          })
          this.currentDIYSubjectId = response.data
          this.createAndBindIncorrectVocProjectSubjectSet(response.data)
        })
        .catch(error => console.log(error))
    },

    generateCurrentDateString: function () {
      let now = new Date()
      return `${now.getFullYear()}.${now.getMonth() + 1}.${now.getDate()}-${
        this.decodedQueryObject.subjectName
      }`
    },

    createAndBindIncorrectVocProjectSubjectSet: function (subjectId) {
      let setName = this.generateCurrentDateString()
      this.$store
        .dispatch('Set/add', {
          userId: this.targetId,
          name: setName
        })
        .then(response => {
          ElMessage({
            message: '添加集合.',
            type: 'success'
          })
          console.log(response)
          this.addSetToSubject(response.data, subjectId)
        })
        .catch(error => console.log(error))
    },

    addSetToSubject: function (setId, subjectId) {
      this.$store
        .dispatch('Set/addSetToSubject', {
          vocSetId: setId,
          vocSubjectId: subjectId
        })
        .then(() => {
          ElMessage({
            message: '绑定集合到科目.',
            type: 'success'
          })
          this.currentDIYSetId = setId
          //最终完成set到subject的绑定后，产生并添加词汇
          this.addIncorrectWordsToSet()
        })
        .catch(error => console.log(error))
    },

    //最终完成set到subject的绑定后，产生并添加词汇
    addIncorrectWordsToSet: function () {
      let vocToPost = this.generateManyVocPostData()
      if (!vocToPost || vocToPost.length <= 0) {
        ElMessage({
          message: '没有可添加的词汇.',
          type: 'success'
        })
      } else {
        this.$store
          .dispatch('Vocabulary/batchly', vocToPost)
          .then(() => {
            ElMessage({
              message: '词汇已添加.',
              type: 'success'
            })
          })
          .catch(error => ElMessage.error(error.toString()))
      }
    },

    generateManyVocPostData: function () {
      if (!this.currentMarkedVocs || this.currentMarkedVocs.length <= 0)
        return []

      let result = []
      for (let i = 0; i < this.currentMarkedVocs.length; i++) {
        let temp = {
          updatedBy: this.userId,
          vocSetId: this.currentDIYSetId,
          englishText: this.currentMarkedVocs[i].englishText,
          chineseText: this.currentMarkedVocs[i].chineseText,
          spelling: this.currentMarkedVocs[i].spelling
        }
        result.push(temp)
      }
      return result
    },
    /////////$$$$$ 添加标记的错词的完整逻辑 - 结束 ////////

    handleDeactivateExamine: function (task) {
      this.$store
        .dispatch(
          'StudentPlan/updateStudentPlanDetailTaskIsExaminingAsStudent',
          {
            taskId: task.id,
            isExaminingAsStudent: false
          }
        )
        .then(response => {
          if (response.data > 0) {
            this.currentTask.isExaminingAsStudent = false
          }
        })
    },

    handleProcedureStepClick: function (step, index) {
      if (
        this.manipulatingProcedure.length <= 0 &&
        this.cacheVocListInProcedureSteps.length <= 0
      )
        return

      this.manipulatingProcedure = this.manipulatingProcedure.slice(
        0,
        index + 1
      )
      this.cacheVocListInProcedureSteps =
        this.cacheVocListInProcedureSteps.slice(0, index + 1)
      this.vocList = this.cacheVocListInProcedureSteps[index]
      this.initializeVocList()
    },

    handleTotalCountClick: function () {
      this.vocList = Array.from(
        this.vocList
          .reduce((acc, obj) => acc.set(obj.englishText, obj), new Map())
          .values()
      )
      this.initializeVocList()
      console.log(this.vocList)
    },

    handleVocKeyDown: function (event) {
      //console.log(event.keyCode)
      //空格键从点击单词卡，变成发音
      switch (event.keyCode) {
        case 32:
          //this.handleVocClick();
          this.playCurrentVoc()
          //event.returnValue = false;
          break
        case 37:
          this.handlePrevClick()
          event.returnValue = false // 防止事件冒泡
          break
        case 39:
          this.handleNextClick()
          event.returnValue = false
          break
        case 38:
          this.handleVocClick()
          event.returnValue = false
          break
        case 40:
          this.handleVocClick()
          event.returnValue = false
          break
        //一直点问号会有标记count的bug - ?bug 已修复
        case 191:
          //在add标记之前添加overview detail，因为有时候标记会失败，但是overview却可以update数据，只是没有具体标记的词
          //标记单词时，在overview的detail里面去更新这个detail; isCorrect 是false，因为标记的词表明学生回答错误
          this.upsertVocStudentOverviewDetail(false)
          this.addOrCancelMark(true)
          event.returnValue = false
          break
        //点，按键，点击后直接标黑当前词汇 190
        case 190:
          //在add标记之前添加overview detail，因为有时候标记会失败，但是overview却可以update数据，只是没有具体标记的词
          //标记单词时，在overview的detail里面去更新这个detail; isCorrect 是false，因为标记的词表明学生回答错误
          this.upsertVocStudentOverviewDetail(false)
          this.markAsBlack()
          event.returnValue = false
          break

        case 173:
          this.minusMark()
          event.returnValue = false
          break
        // - _ 按键 189 for mac
        case 189:
          this.minusMark()
          event.returnValue = false
          break
        // `~ 按键 192 to play current voc
        case 192:
          this.playVocByYoudao()
          event.returnValue = false
          break
        // s 按键 83 过滤学生专属星标
        case 83:
          this.filterStudentMarkedVoc()
          event.returnValue = false
          break
        //控制定制选择2个范围同时选取星标和非星标词汇数的弹窗; 快捷键 d
        case 68:
          this.showCustomizedFilterForm = true
          document.removeEventListener('keydown', this.handleVocKeyDown) // 打开form的时候，移除keydown全局事件
          event.returnValue = false
          break
        //随机快捷键 r
        case 82:
          this.shuffleVoc()
          //启动一个是否加入overviewDetails的参数，如果加入，就addStudentOverview，否则不变
          if (this.isOverviewDetailsAdded) {
            this.addStudentOverview()
          }
          //每次按r随机的时候，触发一次add，但是在add之前需要一个debounce，防止学生一直按r，导致触发太多无效数据
          // this.debounce(
          //   this.addStudentOverviewFinal,

          //   2000
          // )();
          event.returnValue = false
          break
        //学生专属星标快捷键 m
        case 77:
          this.handleStudentMarkClick()
          //this.addStudentOverview();
          event.returnValue = false
          break
        //过滤所有星标快捷键 h
        case 72:
          this.filterAllMarkedVoc()
          //this.addStudentOverview();
          event.returnValue = false
          break
        //过滤所有黑色词汇快捷键 b
        case 66:
          this.filterVocByMarkedCount(6)
          //this.addStudentOverview();
          event.returnValue = false
          break
        //过滤所有未标记的词 z
        case 90:
          this.filterVocListWithNoHeart()
          //this.addStudentOverview();
          event.returnValue = false
          break
        //选出Evian全局测试的100个词，快捷键，从E（69）调整为V
        case 86:
          this.EvianTestShortcut()
          event.returnValue = false
          break
        //选出Naive lists的随机100个词测试，快捷键，N
        case 78:
          this.NaiveTestShortcutPre()
          event.returnValue = false
          break
        //选出Naive 全局50个词测试，快捷键，I
        case 73:
          this.NaiveTestShortcutPrePost()
          event.returnValue = false
          break
        //选出New Happy 全局随机50个词，快捷键，Y
        case 89:
          this.NewHappyGlobal()
          event.returnValue = false
          break
        //键盘上方按键1，过滤出1个星标的单词
        case 49:
          this.filterVocListByHeart([1])
          event.returnValue = false
          break
        //键盘上方按键2，过滤出2个星标的单词
        case 50:
          this.filterVocListByHeart([2])
          event.returnValue = false
          break
        //键盘上方按键3，过滤出3个星标的单词
        case 51:
          this.filterVocListByHeart([3])
          event.returnValue = false
          break
        //键盘上方按键4，过滤出4个星标的单词
        case 52:
          this.filterVocListByHeart([4])
          event.returnValue = false
          break
        //键盘上方按键5，过滤出5个星标以上的单词
        case 53:
          this.filterVocListByHeart([5])
          event.returnValue = false
          break
        //q, 英文检查模式
        case 81:
          this.handleCheckingModeClick(1)
          event.returnValue = false
          break
        //w, 中文检查模式
        case 87:
          this.handleCheckingModeClick(2)
          event.returnValue = false
          break
        //e, 音频检查模式
        case 69:
          this.handleCheckingModeClick(4)
          event.returnValue = false
          break
        //按P读出中文文本里面的例句
        // case 80:
        //   this.playCurrentExampleSentence();
        //   event.returnValue = false;
        //   break;
        //按T 选择阶段性词测选择100个标记的快捷键
        case 84:
          this.MilestoneTestShortcut()
          event.returnValue = false
          break
        //按L 用当前文章名称获得listening中该文章的所有句子，匹配当前句子，播放
        case 76:
          this.playSentenceIfAny()
          event.returnValue = false
          break
        //按f 过滤当前多义词，不管多义词标签是否点亮
        case 70:
          this.filterVocListWithMultiMeaning()
          event.returnValue = false
          break
        //按k 尝试播放Chinese里面隐藏了音频链接的音频
        // case 75:
        //   this.playListeningSentence(0);
        //   event.returnValue = false;
        //   break;
        // Add a case for Ctrl+G key combination
        case 71: // 71 is the key code for 'g'
          if (event.ctrlKey) {
            // Your action when Ctrl+G is pressed
            this.williamDialogVisible = !this.williamDialogVisible
            event.returnValue = false
          }
          break
      }
    },

    //for cellphone
    touchStart: function (e) {
      this.forCellPhoneEvent.startX = e.touches[0].clientX
      this.forCellPhoneEvent.startY = e.touches[0].clientY
    },

    touchMove: function (e) {
      this.forCellPhoneEvent.moveX = e.touches[0].clientX
      this.forCellPhoneEvent.moveY = e.touches[0].clientY
      //this.forCellPhoneEvent.startX - this.forCellPhoneEvent.moveX <=0 ? console.log("right") : console.log("left")
    },

    touchEnd: function (e) {
      this.forCellPhoneEvent.endX = e.changedTouches[0].clientX
      this.forCellPhoneEvent.endY = e.changedTouches[0].clientY
      //console.log(this.forCellPhoneEvent.startX - this.forCellPhoneEvent.endX);
      if (this.forCellPhoneEvent.startX - this.forCellPhoneEvent.endX > 25) {
        //moving left
        this.handleNextClick()
      }
      if (this.forCellPhoneEvent.startX - this.forCellPhoneEvent.endX < -25) {
        //moving right
        this.handlePrevClick()
      }
      // this.forCellPhoneEvent.startX - this.forCellPhoneEvent.endX <= 100
      //   ? this.handlePrevClick()
      //   : this.handleNextClick();
    }
    //for cellphone
  },

  beforeUnmount: function () {
    document.removeEventListener('keydown', this.handleVocKeyDown) // remove global key down event before leaving voc page
    document.addEventListener(
      'keyup',
      this.debounce(this.addStudentOverviewFinal, 1000)
    )
    document.querySelector('body').removeAttribute('style') // remove background color in body style
  }
}
</script>
<style scoped>
.card-header {
  display: flex;
  justify-content: space-between;
  padding: 0 1.5rem;
  background-color: white;
  box-shadow: 0 0.25rem 1rem 0 rgba(48, 53, 69, 0.08);
}

.andover-logo-area {
  font-size: bold;
  font-family: hurme_no2-webfont, -apple-system, BlinkMacSystemFont, sans-serif;
}

.inside-header-left,
.inside-header-right {
  margin: auto 0;
  width: 30rem;
  text-align: left;
}

.inside-header-right {
  display: flex;
}

.refresh-icon,
.tools-option {
  font-size: 100%;
  line-height: 1.625;
  margin-left: 1rem;
}

.help {
  margin-left: 1rem;
}

div#outsideContainer {
  background-color: rgb(246, 247, 251);
}

.navi-text {
  font-size: 1rem;
  letter-spacing: normal;
  line-height: 1.5;
  font-weight: 600;
  font-family: hurme_no2-webfont, -apple-system, BlinkMacSystemFont, sans-serif;
}

.card-wrapper {
  display: flex;
  flex-flow: row wrap;
  height: 100%;
  margin-top: 30px;
}

.card-wrapper > * {
  flex: 1 100%;
}

.card-content {
  border-radius: 5px 5px 0 0;
  margin-left: 19%;
  margin-right: 19%;
  background-color: white;
  box-shadow: 0 0.25rem 1rem 0 rgba(48, 53, 69, 0.08);
}

article {
  min-height: 280px;
}

/* header {
  border-radius: 5px 5px 0 0;
  margin-left: 30%;
  margin-right: 30%;
  background-color: white;
} */

.header-container {
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
}

.heart-container {
  display: flex;
  justify-content: space-around;
  cursor: pointer;
  color: deepskyblue;
  font-size: 18px;
}

.voc-player {
  /* margin-left: 30%;
  margin-right: 30%;
  background-color: white; */

  display: flex;
  justify-content: space-around;
  /* border-radius: 0px 0px 5px 5px; */
}

.controller-left,
.controller-right {
  font-size: 2.3rem;
  cursor: pointer;
  margin: auto;
  opacity: 0.1;
  transition: opacity 1s ease-in-out;
}

.controller-left:hover,
.controller-right:hover {
  font-size: 2.3rem;
  cursor: pointer;
  margin: auto;
  opacity: 1;
  transition: opacity 1s ease-in-out;
}

footer {
  /* margin-left: 30%;
  margin-right: 30%;
  background-color: white; */
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  /* border-radius: 0px 0px 5px 5px; */
}

div#scriptContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

div#scriptContainer,
div#EnScriptContainer,
div#CnScriptContainer,
#showEnCn {
  /* position: relative; */

  /* margin-left: 30%;
  margin-right: 30%;
  background-color: white; */
}

#showEnCn {
  min-height: 280px;
}

div#scriptContainer {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

div#CnScriptContainer {
  position: absolute;
  min-height: 280px;
  top: 0px;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  /* padding-top: 110px; */
}

/*student mark保持在页面最上面，所以设置z-index； margin的top和left的值是根据flex子元素下居中之后调整出来的；不同页面分辨率显示会有不同 */
/* .student-mark {
  z-index: 100;
  margin-bottom: 520px;
  margin-left: 97%;
} */

div#MaskLayer {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: white;
  border-radius: 5px;
}

.textUponMask {
  margin-top: 10px;
  z-index: 999;
}
div.EnText {
  font-size: 2.3em;
}

div.SpellingText {
  font-size: 2.3em;
}

div.CnText {
  font-size: 2em;
  text-align: left;
}

.image {
  width: 20rem;
  margin-left: 5rem;
  float: left;
}

.full-image {
  position: absolute;
  margin-top: -410px;
}

.full-image img {
  height: 100%;
  width: 100%;
}

.vocExampleText {
  font-size: 1.3rem;
}

.iconfont {
  color: deepskyblue;
  cursor: pointer;
}

.deepskyblue {
  color: deepskyblue;
}

.red {
  color: red;
}

.purple {
  color: purple;
}

.clickPointer {
  cursor: pointer;
}

.card-controller {
  border: 1px solid #b9b9b9;
  border-bottom: none;
  border-radius: 5px;
  margin-top: 10px;
  display: flex;
  margin: auto;
}

#VocProgressBar {
  padding-top: 12px;
}

.mark-heart {
  margin-left: 3px;
}

.progress-bar {
  margin-top: 10px;
}

#ListeningToolsBar {
  padding-top: 5px;
}

.heart-and-tools {
  margin-left: 10px;
  margin-top: 4px;
}

.tags {
  margin-right: 0px;
  padding-bottom: 5px;
}

.redheart {
  color: red;
}

.add-many-plugin {
  margin-top: 10px;
}

.listening-progress-bar {
  position: fixed;
  zoom: 1;
  bottom: 2rem;
  left: 19%;
  right: 19%;
  width: 62%;
  z-index: 1002;
  height: auto;
}

.checking-mode-switch {
  border: 1px solid deepskyblue;
  border-radius: 1em;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.hide {
  opacity: 0.3;
}

.help-image {
  width: 100%;
}

.correct {
}
.insertion {
  color: red;
  text-decoration: line-through;
}
.substitution {
  color: red;
}
.deletion {
  color: red;
  opacity: 0.3;
}

.video-player-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-player {
  position: relative;
}

video {
  max-width: 80%;
}

.video_button {
  position: absolute;
  top: 10px;
  right: 10px;
}
</style>
